.card-sin-jo a {
  position: relative;
  display: inline-block;
  padding: 10px 16px;
  color: #1a1a1a;
  margin: 0 12px;
  font-weight: 300;
  font-size: 18px;
}
.card-sin-jo p {
  position: relative;
  display: inline-block;
  padding: 10px 16px;
  color: #1a1a1a;
  margin: 0 12px;
  font-weight: 300;
  font-size: 18px;
}

.card-sin-jo a i {
  font-size: 20px;
  color: #131415;
}

.card-sin-jo span {
  position: absolute;
  top: 0;
  background: #003d94;
  height: 18px;
  width: 18px;
  display: flex;
  color: #fff;
  justify-content: center;
  border-radius: 50%;
  right: 0;
  font-size: 10px;
  padding-top: 2px;
}

.card-sin-jo {
  display: flex;
  align-items: center;
  justify-content: center;
}

p.bg-0033a1 {
  color: #fff;
  background: #0033a1;
}

.bg-0033a1:hover {
  background: #0aa0e4;
}

.mobile-menu {
  display: none;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}
