/* Kleiser Custom CSS */
@import "./Responsive.css";

.card_btn_icon {
  color: white;
  margin: 5px;
}

.card_btn_icon_fav {
  color: #ff4949;
  margin: 5px;
}

p.bg-0033a1 {
  color: #fff;
}

.bg-0033a1 {
  background: #0033a1;
}

.bg-0033a1:hover {
  background: #0aa0e4;
}

.mobile-menu {
  display: none;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}
