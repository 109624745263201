/*=============================================================
fonts
=============================================================*/
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@font-face {
  font-family: "BentonSans";
  src: url("/fonts/benton/BentonSans-Bold.eot");
  src: url("/fonts/benton/BentonSans-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/benton/BentonSans-Bold.woff2") format("woff2"),
    url("/fonts/benton/BentonSans-Bold.woff") format("woff"),
    url("/fonts/benton/BentonSans-Bold.ttf") format("truetype"),
    url("/fonts/benton/BentonSans-Bold.svg#BentonSans-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "BentonSans";
  src: url("/fonts/benton/BentonSans-Light.eot");
  src: url("/fonts/benton/BentonSans-Light.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/benton/BentonSans-Light.woff2") format("woff2"),
    url("/fonts/benton/BentonSans-Light.woff") format("woff"),
    url("/fonts/benton/BentonSans-Light.ttf") format("truetype"),
    url("/fonts/benton/BentonSans-Light.svg#BentonSans-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "BentonSans";
  src: url("/fonts/benton/BentonSans-Medium.eot");
  src: url("/fonts/benton/BentonSans-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/benton/BentonSans-Medium.woff2") format("woff2"),
    url("/fonts/benton/BentonSans-Medium.woff") format("woff"),
    url("/fonts/benton/BentonSans-Medium.ttf") format("truetype"),
    url("/fonts/benton/BentonSans-Medium.svg#BentonSans-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "BentonSans";
  src: url("/fonts/benton/BentonSans.eot");
  src: url("/fonts/benton/BentonSans.eot?#iefix") format("embedded-opentype"),
    url("/fonts/benton/BentonSans.woff2") format("woff2"),
    url("/fonts/benton/BentonSans.woff") format("woff"),
    url("/fonts/benton/BentonSans.ttf") format("truetype"),
    url("/fonts/benton/BentonSans.svg#BentonSans") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: "BentonSans";
  overflow-x: hidden;
  margin: 0;
  color: #1a1a1a;
  background: #f4f4f4 !important;
}

* {
  box-sizing: border-box;
}

.container {
  max-width: 1230px;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-right: 10px;
  padding-left: 10px;
}

/*============= styles ===================*/

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
a,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  color: #1a1a1a;
}

p {
  font-size: 16px;
  line-height: 1.6;
  font-weight: 400;
}

a {
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
}

h2 {
  font-size: 40px;
  line-height: 1.3;
  color: #157bc0;
}

h3 {
  font-size: 32px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 18px;
}

.area {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 15px;
}

.theme-btn {
  background: #157bc0;
  padding: 14px 26px;
  border: none;
  color: #fff;
  border-radius: 15px;
  font-size: 20px;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s ease;
}

.theme-btn:hover {
  background: #38b6ff;
}

.bg-0033a1 {
  background: #0033a1;
}

.bg-0AA0E4 {
  background: #0aa0e4;
}

::-webkit-input-placeholder {
  color: #1a1a1a;
}

:-ms-input-placeholder {
  color: #1a1a1a;
}

::placeholder {
  color: #1a1a1a;
}

.menu-src-bar input::placeholder {
  color: rgba(124, 134, 141, 0.8);
}

.posted-rearch input::placeholder {
  color: rgba(124, 134, 141, 0.8);
}

* {
  box-sizing: border-box;
}

input:active,
input:focus,
button:focus,
button:active {
  outline: none;
  box-shadow: none;
}

.section-padding {
  padding: 65px 0;
}
:focus {
  outline: none;
  box-shadow: 0;
}
/* ~**************** Header-Area **************** ~ */

.top-header-close {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 30px;
  color: #0aa0e4;
}

.top-header {
  position: relative;
}

.slider-item {
  height: 660px;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  position: relative;
  z-index: 99;
}

.slider-item::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  border: solid 1px #979797;
  background-color: #090a0b;
  content: "";
  z-index: -1;
}

.slider-home-1-bg {
  background: url(/images/slider/house-slider-1.jpg);
}

.slider-car-1-bg {
  background: url(/images/slider/car-slider-1.jpg);
}

.slider-home-2-bg {
  background: url(/images/slider/house-slider-2.jpg);
}

.slider-car-2-bg {
  background: url(/images/slider/car-slider-2.jpg);
}

.slider-home-3-bg {
  background: url(/images/slider/house-slider-3.jpg);
}

.slider-car-3-bg {
  background: url(/images/slider/car-slider-3.jpg);
}

.slider-home-4-bg {
  background: url(/images/slider/house-slider-4.jpg);
}

.slider-car-4-bg {
  background: url(/images/slider/car-slider-4.jpg);
}

.slider-1-bg {
  background: url(/images/slider-1.jpg);
}

.slider-2-bg {
  background: url(/images/slider-1.jpg);
}

.slider-3-bg {
  background: url(/images/slider-1.jpg);
}

.slider-4-bg {
  background: url(/images/slider-1.jpg);
}

.slider-5-bg {
  background: url(/images/slider-1.jpg);
}

.slider-6-bg {
  background: url(/images/slider-1.jpg);
}

.slider-7-bg {
  background: url(/images/slider-1.jpg);
}

.slider-area .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  right: 10%;
  top: 10%;
  width: 30px;
}

.slider-area .owl-dots .owl-dot.active span,
.owl-dots .owl-dot:hover span {
  background: #00a1e0;
}

.slider-area .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: rgba(124, 134, 141, 0.4);
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 0.2s ease;
  border-radius: 1px;
}

.banner-btn {
  display: flex;
  margin: 50px 0;
}

.banner-btn a {
  background: #979797;
  padding: 10px 60px;
  font-weight: 500;
  transition: all 0.3s ease;
  text-transform: uppercase;
  margin-right: 1px;
  color: #1a1a1a;
}

.banner-btn a:hover {
  background: #0033a1;
  color: #fff;
}

.banner-content h2 {
  font-size: 55px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 20px;
}

.banner-content h5 {
  font-size: 23px;
  color: #fff;
}

.posted-houses-area {
  position: relative;
  margin-top: -300px;
  z-index: 99;
}

.posted-rearch {
  position: relative;
}

.posted-rearch input {
  background: #f4f4f4;
  border: none;
  padding: 0 65px;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  height: 50px;
}

button.srcicon {
  position: absolute;
  right: 0;
  top: -4px;
  border: none;
  background: #0aa0e4;
  height: 58px;
  padding: 0 12px;
  box-shadow: 0 0px 26px 0 rgba(0, 0, 0, 0.5);
}

img.home-icon {
  position: absolute;
  left: 20px;
  top: 13px;
}

h2.fost-main-heading {
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  margin: 50px 0 0px;
}

.post-item-img {
  overflow: hidden;
  /* height: 376px; */
}
/* .post-item-img a {
  display: block;
} */
.post-item-img img {
  width: 100%;
  transition: all 0.6s ease;
}

.post-item {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.07);
  position: relative;
  margin-top: 20px;
  background: #fff;
}

img {
  transition: all 0.6s ease;
}

.post-item:hover .post-item-img > a img,
.agencies-item:hover .agencies-logo img,
.agencies-city-item:hover .agencies-city-img img {
  transform: scale(1.1);
}

.post-item .po-share img {
  width: 25px;
}

.post-item .po-home-cont-top {
  position: absolute;
  top: 0;
  width: 100%;
}

.po-share span img {
  display: block;
}

.post-item .po-home-item-headitop h4 {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 0;
}

.post-item .post-item-content {
  padding: 15px 10px 20px;
}

.post-item .po-home-item-heading p {
  font-size: 10px;
}

/*
.post-item .post-item-content {
  padding: 10px;
}
*/

.post-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.post-flex h6 {
  font-size: 10px;
}

.post-flex h5 {
  font-size: 14px;
  color: #00af43;
}

.post-flex h5 strong {
  font-weight: 300;
  margin-left: 20px;
}

.post-flex h5 span {
  color: #00af43;
}

.post-flex h6 {
  font-size: 12px;
  color: #00af43;
  font-weight: 300;
}

.more-post {
  text-align: center;
  padding: 40px 0 20px;
}

button.more-post-all {
  padding: 10px 50px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  border: none;
}

.agencie-page {
  margin-top: 40px;
}

.agencies-logo a {
  display: block;
}

.agencies-logo {
  height: 200px;
  text-align: center;
  overflow: hidden;
}

.gencies-registred-warp {
  background: #fff;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.07);
  margin: 30px 0;
  padding: 30px 0 50px;
}

h3.gencies-registred {
  text-align: center;
  color: #0033a1;
  font-size: 22px;
  font-weight: 600;
}

.agencies-item {
  background: #fff;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.07);
  padding: 0 15px;
  margin-top: 20px;
}

.agencies-content h3 {
  font-size: 22px;
  margin-top: 20px;
  font-weight: 600;
}

.agencies-item .po-bed-items {
  border-bottom: none;
}

.agencies-item .po-bedi-cont {
  text-align: left;
}

.agencies-city {
  background: #fff;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.07);
  margin: 0 0 50px;
  padding: 30px 0 50px;
}

.agencies-city-items {
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-template-rows: minmax(100px, auto);
  grid-auto-flow: dense;
  grid-gap: 10px;
  padding: 20px 20px 0;
  text-align: center;
}

.agencies-city-item {
  margin-top: 30px;
}

.agencies-city-img {
  overflow: hidden;
  height: 226px;
}

.agencies-city-img img {
  width: 100%;
}

.agencies-city-item p {
  margin: 20px 0 0;
  display: block;
  font-size: 15px;
  font-weight: 600;
  color: #131415;
  transition: all 0.3s ease;
}

.agencies-city-item:hover p {
  color: #0033a1;
}

.agencies-item-2 {
  padding: 12px;
}

.agencies-logo a img {
  width: auto !important;
  margin: auto;
}

.gencies-registred-warp .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  margin-top: 30px;
}

.gencies-registred-warp .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: rgba(124, 134, 141, 0.4);
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 0.2s ease;
  border-radius: 0px;
}

.gencies-registred-warp .owl-dots .owl-dot.active span,
.owl-dots .owl-dot:hover span {
  background: #00a1e0;
}

.gencies-registred-warp .owl-carousel .owl-item img {
  display: inline-block;
  width: auto;
}

.top-header {
  transition: all 0.3s ease;
  /* max-width: 1200px; */
  margin: auto;
  padding: 10px;
  /* background: #000; */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.top-header p {
  font-weight: 400;
  color: #fff;
  margin: auto;
}

.top-header p span {
  color: #00a1e0;
  margin-right: 5px;
}

.header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 999;
  background: #0033a1;
}

.header-content {
  align-items: center;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.07), 0 2px 13px 0 rgba(0, 0, 0, 0.08);
  background: #fff;
}

.container-area {
  max-width: 1300px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  align-items: center;
  width: 100%;
}

.src--sin-jo {
  display: flex;
  align-items: center;
}

.menu-src-bar {
  position: relative;
  margin-right: 50px;
}

.menu-src-bar input {
  width: 420px;
  padding: 10px;
  background: rgba(124, 134, 141, 0.2);
  border: solid 2px rgba(26, 26, 26, 0.1);
}

.menu-src-bar input:focus,
.filter-src-warp .location-src input:focus {
  border: 2px solid #003d94;
}

button.src-icon {
  position: absolute;
  right: 8px;
  padding: 7px;
  background: transparent;
  top: 50%;
  transform: translateY(-50%);
  border: none;
}

.card-sin-jo a {
  position: relative;
  display: inline-block;
  padding: 10px 16px;
  color: #1a1a1a;
  margin: 0 12px;
  font-weight: 300;
  font-size: 18px;
}

.card-sin-jo a i {
  font-size: 20px;
  color: #131415;
}

.card-sin-jo span {
  position: absolute;
  top: 0;
  background: #003d94;
  height: 18px;
  width: 18px;
  display: flex;
  color: #fff;
  justify-content: center;
  border-radius: 50%;
  right: 0;
  font-size: 10px;
  padding-top: 2px;
}

.card-sin-jo {
  display: flex;
  align-items: center;
  justify-content: center;
}

a.bg-0033a1 {
  color: #fff;
}

.bg-0033a1 {
  background: #0033a1;
}

.bg-0033a1:hover {
  background: #0aa0e4;
}

.bg-0AA0E4:hover {
  background: #0033a1;
}

.logo {
  transition: all 0.3s ease;
}

.logo a {
  display: block;
  font-size: 22px;
  color: #1a1a1a;
  font-weight: 900;
  padding: 0;
}

ul#navigation li {
  display: inline-block;
  position: relative;
  float: left;
}

ul#navigation li a {
  display: block;
  padding: 26px;
  position: relative;
  text-transform: capitalize;
  color: #0033a1;
  font-weight: 400;
  font-size: 20px;
}

ul#navigation .pc-none,
.pc-none {
  display: none;
}

ul#navigation li a.active,
ul#navigation li a:hover {
  color: #fff;
  background: #0aa0e4;
}

.mobile-menu {
  display: none;
}

ul.lang-items li i {
  margin-left: 14px;
}

ul.lang-items li {
  display: flex;
  justify-content: center;
  align-items: center;
}

ul.lang-items > li {
  color: #00a1e0;
  transition: all 0.3s ease;
}

ul.lang-items li.active,
ul.lang-items ul.lang-sub li:hover {
  background: #00a1e0;
  color: #fff;
}

ul.lang-items li.active a,
ul.lang-items ul.lang-sub li:hover a {
  color: #fff;
}

ul.lang-items {
  position: relative;
}

ul.lang-sub li a {
  display: flex;
  align-items: center;
  font-size: 18px;
  margin: 0;
  padding: 6px 0;
}

ul.lang-sub li a img {
  width: 20px;
  margin-right: 4px;
}

ul.lang-sub {
  background: #fff;
  width: 90px;
  padding: 40px 0 0px;
  border-radius: 0 0 2px 2px;
  position: absolute;
  top: 100%;
  opacity: 0;
  visibility: hidden;
}

ul.lang-items li:hover ul.lang-sub {
  opacity: 1;
  visibility: visible;
}

@media only screen and (max-width: 991px) {
  body.sticky .top-header {
    height: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
  }

  .top-header-close {
    right: 4px;
  }

  .mobile-menu {
    cursor: pointer;
    display: inline-block;
    right: 30px;
    top: 47px;
    z-index: 999;
  }

  .bar1,
  .bar2,
  .bar3 {
    background-color: #0033a1;
    height: 2px;
    margin: 6px 0;
    transition: all 0.4s ease 0s;
    width: 30px;
  }

  .change .bar1 {
    transform: rotate(-45deg) translate(-4px, 4px);
  }

  .change .bar2 {
    opacity: 0;
  }

  .change .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
  }

  .transform-active {
    overflow: hidden;
  }

  ul#navigation li a.bg-0033a1 {
    color: #fff;
  }

  .pc-none.mobile-src img {
    margin-top: -10px;
    margin-right: 0;
    width: 18px;
  }

  .menu-area {
    padding: 0 10px;
  }

  ul.lang-sub {
    width: 100%;
    padding: 10px 0 0px;
    overflow: hidden;
  }

  ul.lang-sub li a {
    width: 1000%;
  }

  .mobile-sign-item.pc-none ul.lang-sub li a {
    padding: 4px;
    margin-bottom: 0;
    font-size: 16px;
  }

  ul.lang-sub li a img {
    margin-top: -6px;
  }
}

/*      */
.acencies-area {
  padding: 40px 0;
}

.home-top-menu ul {
  display: flex;
  align-items: center;
}

.home-top-menu ul li a {
  padding: 12px 80px;
  display: block;
  background: #b7c7d7;
}

.home-top-menu ul li a:hover,
.home-top-menu ul li a.active {
  background: #0033a1;
  color: #fff;
}

.filter-src-warp {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.07),
    0 -30px 13px 0 rgba(155, 155, 155, 0.05);
  background: #fff;
  padding: 30px 15px;
}

.filter-src-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter-src-warp p {
  font-size: 14px;
}

.fl-src {
  background: #00a1e0;
  padding: 4px 15px;
}

.fl-src p {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 18px;
  font-weight: 300;
}

.fl-src img {
  width: 18px;
  margin-right: 12px;
}

.fl-labels {
  display: flex;
}

.fl-filter p {
  display: flex;
  align-items: center;
}

.fl-room,
.fl-bathrooms,
.fl-others {
  display: flex;
  align-items: center;
}

.filter-src-warp .fl-room.active p,
.filter-src-warp .fl-room.active i {
  color: #0838a1;
}

.fl-room i,
.fl-bathrooms i,
.fl-others i {
  margin-left: 5px;
}

.fl-form h6 {
  background: #f3f4f8;
  padding: 5px;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  border: 2px solid #b3c1d2;
}

.fl-form {
  position: relative;
}

.fl-form p {
  position: absolute;
  top: -12px;
  font-size: 8px;
  color: #0aa0e4;
  font-weight: 600;
}

span.fl-price-range-line {
  display: block;
  height: 2px;
  width: 160px;
  background: #b3c1d2;
  margin-left: 10px;
}

.fl-price-range-line::before {
  position: absolute;
  top: -6px;
  left: 20%;
  width: 12px;
  height: 12px;
  background: #0aa0e4;
  content: "";
  border-radius: 50%;
  cursor: pointer;
}

.fl-price-range-line::after {
  position: absolute;
  top: -6px;
  right: 5%;
  width: 12px;
  height: 12px;
  background: #0aa0e4;
  content: "";
  border-radius: 50%;
  cursor: pointer;
}

.fl-price {
  display: flex;
  align-items: center;
}

.fl-price-range {
  position: relative;
}

span.fl-price-range-left {
  position: absolute;
  background: #0aa0e4;
  font-size: 8px;
  color: #fff;
  padding: 2px 5px;
  top: -28px;
  left: 14%;
  z-index: 99;
  cursor: pointer;
}

span.fl-price-range-right {
  position: absolute;
  background: #0aa0e4;
  font-size: 8px;
  color: #fff;
  padding: 2px 5px;
  right: 2%;
  top: -28px;
  z-index: 99;
  cursor: pointer;
}

span.fl-price-range-left::after,
span.fl-price-range-right::after {
  position: absolute;
  height: 10px;
  width: 10px;
  background: #0aa0e4;
  bottom: -2px;
  left: 50%;
  content: "";
  transform: translate(-50%) rotate(45deg);
  z-index: -1;
  cursor: pointer;
}

.fl-labels .fl-form {
  margin: 0 4px;
}

.fl-filter,
fl-room,
fl-others {
  cursor: pointer;
}

.fl-clears {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fl-clear-short {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fl-clears {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fl-clear-short {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fl-clear {
  background: #00a1e0;
  padding: 4px 10px;
}

.fl-clear p {
  color: #fff;
  font-size: 10px;
}

.fl-clear-all p {
  font-size: 10px;
}

.fl-clear p span {
  background: rgba(225, 225, 225, 0.2);
  height: 16px;
  width: 16px;
  border-radius: 50%;
  display: inline-block;
  padding-left: 4px;
  font-size: 10px;
  cursor: pointer;
  margin-left: 5px;
  color: #fff;
}

.fl-clear-all {
  cursor: pointer;
  margin-left: 15px;
}

.fl-short-by h6 {
  font-size: 10px;
  font-weight: 900;
}

.fl-short-by h6 span {
  font-weight: 300;
}

.fl-grid-view {
  margin-left: 20px;
}

.fl-src,
.fl-filter,
.fl-room,
.fl-bathrooms,
.fl-others,
.fl-form,
.fl-price,
.fl-labels {
  margin: 0 8px 30px 0;
  cursor: pointer;
}

.filter-src-warp input {
  width: 90px;
  font-size: 11px;
  background: rgba(124, 134, 141, 0.2);
  border: solid 2px rgba(26, 26, 26, 0.1);
  padding: 5px;
  font-weight: 400;
}

.location-src {
  display: flex;
  width: 60%;
}

.filter-src-warp .location-src input {
  width: 100%;
  padding: 10px;
}

.location-src .fl-form {
  width: 50%;
}

.fl-room.fl-rome-wi {
  max-width: 60px;
}

.fl-room.fl-bath-wi {
  max-width: 95px;
}

.fl-room.fl-trans-wi {
  max-width: 106px;
}

.fl-room.fl-fuel-wi {
  max-width: 82px;
}

/*
.fl-room {
  border: none;
  position: relative;
  background-image: url(/images/down-black.png);
  max-width: 70px;
  width: 100%;
  background-position: right 5px;
  background-repeat: no-repeat;
}
*/

.fl-room form input {
  cursor: pointer;
}

/*
.fl-room.active {
  background-image: url(/images/down-active.png);
}
*/

.fl-room select,
.fl-room form {
  width: 100%;
  background: transparent;
}

select,
select:active,
select:focus {
  border: none;
  outline: none;
  -webkit-appearance: none;
  appearance: none;
  font-size: 14px;
  padding: 0 2px;
}

.po-home-item {
  display: flex;
  justify-content: space-between;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.07);
  background: #fff;
  margin: 10px 0 0 0;
}

.po-home-item-headitop h4 {
  font-size: 22px;
  font-weight: 900;
  margin-bottom: 6px;
  transition: all 0.3s ease;
}

.po-home-item-headitop h4:hover {
  color: #0aa0e4;
}

.po-home-item-headitop {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.po-home-item-left {
  width: 57%;
  padding: 20px;
}

.po-home-item-right {
  width: 40%;
  position: relative;
  overflow: hidden;
}

.po-home-img img {
  transition: all 0.6s ease;
  /* object-fit: cover !important;*/
}

.po-home-item-headitop p {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: rgba(124, 134, 141, 0.8);
  cursor: pointer;
}

.po-home-item-headitop p img {
  margin-right: 5px;
}

.po-home-item-heading p {
  font-size: 14px;
  color: rgba(124, 134, 141, 0.8);
}

.po-bed-items {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ececec;
}

.po-bed-item {
  display: flex;
  align-items: center;
  margin: 30px 0 24px;
}

.po-bedicon {
  margin-right: 15px;
}
.po-bedicon img {
  width: 30px !important;
}
.po-bedi-cont h6 {
  font-weight: 900;
}

.po-bedi-cont p {
  font-size: 14px;
  color: rgba(124, 134, 141, 0.8);
}

.po-bedi-cont {
  text-align: center;
}

.po-start-prices {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0 0;
}

.po-start-price {
  width: 30%;
}

.po-start-price.col-00af43 {
  border-right: solid 2px rgba(26, 26, 26, 0.1);
}

.po-start-price h6 {
  font-size: 14px;
  font-weight: 900;
  margin-bottom: 5px;
}

.po-start-price p {
  font-weight: 500;
  display: flex;
  align-items: center;
}

.po-start-price.col-00af43 p span {
  font-size: 14px;
  font-weight: 300;
  margin-right: 14px;
}

.po-start-price.col-00af43 h6,
.po-start-price.col-00af43 p,
.po-start-price.col-00af43 p span {
  color: #00af43;
}

.po-home-item:hover .po-home-img img {
  transform: scale(1.1);
}

.po-start-card {
  display: flex;
}

.po-start-card .bg-0033a1 {
  border: none;
  color: #fff;
  padding: 8px 16px;
  margin-right: 10px;
}

.po-start-card .bg-0AA0E4 {
  border: none;
  padding: 6px 12px;
}

.po-home-cont-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  position: absolute;
  top: 0;
  width: 100%;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.8)
  );
}

.po-photo-vi span {
  display: flex;
  align-items: center;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
}

.po-share {
  display: flex;
  align-items: center;
}

.po-share img {
  margin-left: 15px;
  cursor: pointer;
}

/*~~~~~~~~~~~~~~~~~Car page~~~~~~~~~~~~~~~~~*/

.box-warp {
  background: #fff;
  box-shadow: 0 21px 13px 0 rgba(165, 165, 165, 0.04);
  margin: 30px 0;
  padding: 30px 15px;
}

.po-car-item {
  background: #fff;
  box-shadow: 0 21px 13px 0 rgba(165, 165, 165, 0.06);
  margin-top: 10px;
}

.po-car-items .po-car-item {
  margin-top: 20px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.07);
}

.po-car-item .po-home-item-headitop {
  margin: 15px 0 10px;
}

.po-car-item-img {
  position: relative;
  overflow: hidden;
}

.po-car-item-left a h4 {
  font-size: 18px;
  font-weight: 600;
}

.po-car-item-left h4:hover {
  color: #0056b3;
}

.po-car-items .po-bed-item {
  display: block;
  text-align: left;
}

.po-car-items .po-bedi-cont {
  text-align: left;
  margin-top: 5px;
}

.po-car-items .po-start-price.col-00af43 {
  width: 52%;
}

.po-car-items .po-start-price {
  width: 40%;
}

.po-car-items .po-start-prices {
  margin: 20px 0 0;
}

.po-car-items .po-bed-item {
  margin: 20px 0;
}

.po-car-items .po-start-card .bg-0033a1 {
  width: 78%;
}

.po-car-items .po-start-card .bg-0AA0E4 {
  width: 18%;
}

.po-car-item .po-start-card {
  display: flex;
  margin-top: 20px;
}

.po-car-item .po-home-img img {
  width: 100%;
}

.po-car-item-left {
  padding: 0 10px 20px;
}

/*
.post-item:hover .post-item-img > a img{
  transform: scale(1.1);
}
*/
.po-car-item:hover .po-home-img > a img {
  transform: scale(1.1);
}

/*~~~~~~~~~~~~~~~~~Car page~~~~~~~~~~~~~~~~~*/

/*pagination*/
.pagination {
  margin-top: 50px;
}

.pagination ul {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.pagination ul li a {
  padding: 10px 18px;
  display: block;
  transition: all 0.3s ease;
}

.pagination ul li a:hover {
  background: #0033a1;
  color: #fff;
}

.pagination ul li span {
  padding: 0 10px;
}

.pagination .bg-0AA0E4 a {
  color: #fff;
}

li.bg-0AA0E4.bo-lef {
  border-radius: 6px 0 0 6px;
}

li.bg-0AA0E4.bo-rig {
  border-radius: 0 6px 6px 0;
}

/*pagination*/

.footer-area {
  padding: 30px 0;
  background: #0033a1;
}

.footer-link,
.footer-logo {
  margin-top: 40px;
}

.footer-logo h1 {
  color: #fff;
}

.footer-link h5 {
  color: #398fee;
  margin-bottom: 20px;
  font-size: 16px;
  text-transform: uppercase;
}

.footer-link a {
  color: #fff;
  font-size: 14px;
  display: block;
  font-weight: 500;
  margin-bottom: 8px;
  transition: all 0.3s ease;
}

.footer-link a:hover {
  color: #398fee;
}

.copy-right {
  margin-top: 50px;
}

.copy-right p {
  color: #fff;
  font-size: 10px;
  font-weight: 300;
  letter-spacing: 1.1px;
}

/* ~~~~~~~~~~~~~~~~~  sing Up Page Start ~~~~~~~~~~~~~~~~~ */
.sign-up-area {
  margin-bottom: 40px;
}

.user-signup {
  background: url(/images/home-img/jason-briscoe-AQl-J19ocWE-unsplash.jpg);
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
  padding: 80px 15px 100px;
  min-height: 980px;
}

.dealers-signup {
  background: url(/images/car/boris-m-_CiyeM2kvqs-unsplash.jpg);
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
  padding: 80px 15px 100px;
  min-height: 980px;
}

.agency-information {
  background: url(/images/home-img/florian-schmidinger-b_79nOqf95I-unsplash.jpg);
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
  padding: 80px 15px 100px;
}

.almost-signup {
  background: url(/images/home-img/alberto-castillo-q-mx4mSkK9zeo-unsplash.jpg);
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
  padding: 80px 15px 100px;
  min-height: 980px;
}

.cant-login {
  background: url(/images/home-img/daniel-dinuzzo-qCjolcMFaLI-unsplash.jpg);
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
  padding: 120px 0 100px;
  min-height: 980px;
}

.new-password {
  background: url(/images/car/ildar-garifullin-KnDyg_Oiu_k-unsplash.jpg);
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
  padding: 120px 15px 100px;
  min-height: 980px;
}

.user-signup::after,
.dealers-signup::after,
.agency-information::after,
.almost-signup::after,
.cant-login::after,
.new-password::after {
  background: #090a0b;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  content: "";
  opacity: 0.5;
}

.wid-800 {
  max-width: 800px;
  margin: auto;
  background: #fff;
  padding: 0 0 60px;
  box-shadow: 0 21px 13px 0 rgba(165, 165, 165, 0.04);
}

.wid-410 {
  max-width: 410px;
  margin: auto;
  background: #fff;
  padding: 0 0 60px;
  box-shadow: 0 21px 13px 0 rgba(165, 165, 165, 0.04);
}

.wid-1100 {
  max-width: 1100px;
  margin: auto;
  background: #fff;
  padding: 0 0 60px;
  box-shadow: 0 21px 13px 0 rgba(165, 165, 165, 0.04);
}

.progress {
  height: 4px !important;
  overflow: inherit !important;
  background-color: #ffffff;
  border-radius: 0 !important;
  margin-bottom: 40px !important;
}

.progress-bar {
  overflow: inherit !important;
  color: #fff !important;
  background-color: #00a1e0 !important;
  position: relative !important;
}

span.progress-ps {
  position: absolute !important;
  right: 0 !important;
  top: 10px !important;
  color: #00a1e0;
  font-size: 14px !important;
  font-weight: 600 !important;
}

h2.sign-heading {
  text-align: center;
  margin-bottom: 30px;
  font-size: 55px;
  font-weight: 700;
  color: #1a1a1a;
  padding-top: 50px;
}

.sing-up-flex::after {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  width: 2px;
  background: #f2f2f2;
  content: "";
}

.sing-up-flex {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 0 34px;
}

.sing-up-left,
.sing-up-right {
  width: 46%;
}

.input-box {
  margin-top: 10px;
}

.input-box label {
  margin-bottom: 0;
  font-weight: 500;
}

.input-box input,
.input-box textarea {
  position: relative;
  padding: 10px 15px;
  width: 100%;
  border: 2px solid #1a1a1a;
  margin-bottom: 5px;
  font-size: 16px;
}

.input-box input:focus,
.input-box textarea:focus {
  border: 2px solid #398fee;
}

.email {
  background: url(/images/message.svg);
  background-repeat: no-repeat;
  background-position: right 12px bottom 10px;
}

.name {
  background: url(/images/user.svg);
  background-repeat: no-repeat;
  background-position: right 12px bottom 10px;
}

.password {
  background: url(/images/lock.svg);
  background-repeat: no-repeat;
  background-position: right 12px bottom 10px;
}

.phone {
  background: url(/images/phone.svg);
  background-repeat: no-repeat;
  background-position: right 12px bottom 10px;
}

.iti__flag-container {
  border: 2px solid #000;
  background: transparent;
}

.input-box-2 {
  display: flex;
  justify-content: space-between;
}

.input-box-2 input {
  width: 49%;
}

.phone-code {
  position: relative;
  width: 20%;
}

.phone-phone-num {
  width: 78%;
  position: relative;
}

.phone-phone-num input {
  width: 100%;
}

.phone-code input {
  width: 100%;
}

.phone-code {
  position: relative;
  width: 100px;
}

.phone-phone-num {
  width: 67%;
  position: relative;
}

.phone-phone-num input {
  width: 100%;
}

.phone-code input {
  width: 100%;
}

p.phone-code-img {
  position: absolute;
  top: 14px;
  margin-left: 10px;
  display: flex;
  align-items: center;
}
img.arro-select {
  padding-left: 15px;
}
p.phone-code-img span {
  font-size: 14px;
}

.phone-phone-num p {
  position: absolute;
  top: 12px;
  margin-left: 15px;
}

input.theme-btn.col-000 {
  background: #000;
  border-radius: 0;
  font-weight: 600;
  margin-top: 30px;
}

input.theme-btn.col-000:hover {
  border: 2px solid #398fee;
  background: #398fee;
}

.check-box {
  padding: 18px 0;
}

.check-box [type="checkbox"]:not(:checked),
.check-box [type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}

.check-box [type="checkbox"]:not(:checked) + label,
.check-box [type="checkbox"]:checked + label {
  position: relative;
  padding-left: 3.4em;
  cursor: pointer;
  font-size: 10px;
}

.check-box [type="checkbox"]:not(:checked) + label:before,
.check-box [type="checkbox"]:checked + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 26px;
  height: 26px;
  background: #0033a1;
}

.check-box [type="checkbox"]:not(:checked) + label:after,
.check-box [type="checkbox"]:checked + label:after {
  content: "\2713\0020";
  position: absolute;
  top: 5px;
  left: 5px;
  font-size: 20px;
  line-height: 0.8;
  color: #fff;
  transition: all 0.2s;
  font-weight: 900;
}

.check-box [type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}

.check-box [type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

.check-box [type="checkbox"]:disabled:not(:checked) + label:before,
.check-box [type="checkbox"]:disabled:checked + label:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}

.check-box [type="checkbox"]:disabled:checked + label:after {
  color: #999;
}

.check-box [type="checkbox"]:disabled + label {
  color: #aaa;
}

.sing-up-right h6 {
  margin-bottom: 15px;
}

a.face-book {
  background: #4267b2;
  padding: 12px;
  justify-content: center;
  position: relative;
  width: 100%;
  display: block;
  text-align: center;
  transition: all 0.3s ease;
}

a.face-book:hover {
  background: #0aa0e4;
}

a.face-book span {
  color: #fff;
  font-weight: 500;
  text-align: center;
}

a.face-book img {
  position: absolute;
  left: 22px;
}

a.continue-google {
  background: transparent;
  padding: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
  display: block;
  text-align: center;
  border: 2px solid #ff4141;
  margin: 15px 0;
  transition: all 0.3s ease;
}
a.continue-google:hover {
  background: #ff4141;
  border: 2px solid #4267b2;
  color: #fff;
}

a.continue-google span {
  color: #000;
  font-weight: 500;
  text-align: center;
  transition: all 0.3s ease;
}

a.continue-google:hover span {
  color: #fff;
}
a.continue-google img {
  position: absolute;
  left: 22px;
}

.sing-up-right p {
  color: #cbcfd1;
  font-size: 10px;
  font-weight: 400;
}

.already-account p {
  font-size: 14px;
  font-weight: 600;
  color: #1a1a1a;
  margin-top: 15px;
}

.already-account p a {
  color: #00a1e0;
  margin-left: 4px;
}
.already-account p a:hover {
  color: #4267b2;
}

/* ~~~~~~~~~~~~~~~~~  sing Up Page End ~~~~~~~~~~~~~~~~~ */

.pd-area {
  padding: 0 40px;
}

h5.co-info-he {
  color: #0033a1;
  font-weight: 600;
  margin: 30px 0 5px;
}

.agency-information-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.agency-information-items .input-box {
  width: 32%;
}

.input-box-2 .city-items {
  width: 48%;
}

select#city {
  border: 2px solid #1a1a1a;
  width: 100%;
  padding: 9px;
  font-size: 16px;
  background: url(/images/down.svg);
  background-repeat: no-repeat;
  background-position: right 12px bottom 12px;
  margin-bottom: 6px;
}
select#city:focus {
  border: 2px solid #398fee;
}

.location-item {
  display: flex;
  align-items: center;
}
.location-item a {
  display: flex;
  align-items: center;
  color: #00a1e0;
  font-weight: 600;
}
.location-item a span {
  display: flex;
  align-items: center;
  color: #00a1e0;
  font-weight: 600;
}
.location-item img {
  margin-right: 10px;
}
.upload-file p {
  font-size: 12px;
  margin-left: 5px;
}
.attachment-file-items {
  display: flex;
  justify-content: space-between;
}

.attachment-file-item label {
  font-weight: 500;
}
.upload-file {
  display: flex;
  align-items: center;
}
.uploaded .upload-file-img {
  background: transparent;
  border: 2px solid #1a1a1a;
}
.upload-file-img {
  background: #00a1e0;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.uploading-time .progress-bar {
  overflow: hidden;
}
.uploading-time .progress {
  height: 10px;
  overflow: hidden;
  background-color: #dceeff;
  border-radius: 0;
  margin-top: 11px;
  margin-bottom: 0;
}
.uploading-time span.progress-ps {
  position: inherit;
  right: 0;
  top: 0;
  color: #fff;
  font-size: 8px;
  font-weight: 400;
}
.agency-information .input-box label {
  margin-bottom: 0px;
  font-weight: 500;
  padding-top: 5px;
}

.back-register {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.back-register button.back-btn.theme-btn {
  max-width: 320px !important;
  width: 100%;
}
.back-register button.theme-btn {
  border-radius: 0;
  padding: 8px 40px;
  border: 2px solid #157bc0;
}
.back-register button.theme-btn:hover {
  border: 2px solid #38b6ff;
}

.back-register button.theme-btn.bg-none {
  background: transparent;
  border: 2px solid #000;
  color: #000;
}
.back-register button.theme-btn.bg-none:hover {
  background: #38b6ff;
  border: 2px solid #38b6ff;
  color: #fff;
}

.almost-signup h2.sign-heading {
  text-align: left;
  margin: 50px 0 5px;
}
.almost-signup h6 {
  font-size: 14px;
  margin-top: 10px;
}
.almost-signup h6 a {
  color: #0033a1;
}

.profile-img {
  width: 128px;
  overflow: hidden;
  height: 128px;
  border-radius: 50%;
}

.profile-img img {
  width: 100%;
}
.valid-email p {
  color: #ff4949;
  font-weight: 600;
  font-size: 14px;
}

.resend-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 20px;
}

.resend-link h6 {
  margin-right: 10px;
}

.resend-link a {
  color: #0033a1;
  font-weight: 500;
}

.resend-link button {
  color: #0033a1;
  font-weight: 500;
}

a.return-log {
  display: flex;
  align-items: center;
  color: #0033a1;
  font-weight: 500;
}
.cant-login input.theme-btn.col-9496a1 {
  width: 100%;
  border-radius: 0;
  margin: 12px 0;
  background: #9496a1;
  padding: 10px;
}
.cant-login input.theme-btn.col-9496a1:hover {
  background: #38b6ff;
}
.cant-login h3.sign-heading,
.new-password h3.sign-heading {
  font-size: 28px;
  padding: 50px 0 10px;
  font-weight: 600;
}
.cant-login h3.sign-heading + h6,
.new-password h3.sign-heading + h6 {
  font-weight: 600;
  margin-bottom: 30px;
}

.new-password .wid-410 {
  padding: 40px 0;
}
.new-password input.theme-btn {
  border: 2px solid #157bc0;
  border-radius: 0;
  margin-bottom: 20px;
}
.error-box {
  background: #ff4949;
  width: 320px;
  display: flex;
  align-items: flex-start;
  padding: 15px;
  position: absolute;
  top: 50px;
  right: 50px;
}

.error-content {
  margin-left: 10px;
}

.error-content h4,
.error-content p {
  color: #fff;
}

.error-content p {
  line-height: 1.3;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 300;
}

.error-content h4 {
  font-size: 28px;
}

.error-cloge {
  position: relative;
  margin-top: -10px;
}

.pagination-area ul li {
  height: 10px;
  width: 10px;
  background: #dceeff;
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;
}
.pagination-area ul li.active,
.pagination-area ul li:hover {
  background: #00a1e0;
}

.pagination-area {
  margin: 40px 0;
  text-align: center;
}

h6.errer-company,
h6.errer-fiscal,
h6.errer-phone,
h6.errer-city,
h6.error-all {
  color: #ff4949;
  font-weight: 600;
  font-size: 14px;
}
h6.error-all {
  float: right;
  position: relative;
  margin-top: -30px;
}

/*  ~~~~~~~~~~~~~~~~~~~~~~~~~~~   Product View Page    ~~~~~~~~~~~~~~~~~~~~~  */
.back-menu {
  margin-top: 30px;
}
.back-menu ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.back-menu ul li {
  margin-right: 30px;
}

.back-menu ul li a {
  color: #00a1e0;
  font-size: 14px;
  font-weight: 3;
}
.back-menu ul li.back-page a {
  font-size: 16px;
  font-weight: 500;
  color: #0033a1;
}

.back-menu ul li.back-page a i {
  color: #0033a1;
  font-size: 16px;
}
.back-menu ul li a i {
  color: rgba(26, 26, 26, 0.2);
  font-size: 14px;
  margin-right: 2px;
}

.back-menu h2 {
  color: #1a1a1a;
  font-size: 55px;
  font-weight: 600;
  padding: 20px 0 5px;
}
.back-menu h5 {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.back-menu h5 img {
  width: 100px;
}

.color-item img {
  opacity: 0.2;
}
.center .color-item img {
  opacity: 1;
}

.colors-area .owl-nav {
  position: absolute;
  top: 50%;
  width: 100%;
  margin-top: -30px;
  transform: translateY(-50%);
  height: 140px;
}

.colors-area button.owl-next {
  position: absolute;
  right: 20%;
}

.colors-area button.owl-prev {
  position: absolute;
  left: 20%;
}
.color-items {
  margin: 30px 0 0;
}

.available-color {
  margin: auto;
  max-width: 325px;
  text-align: center;
}

h3.avilable-color-heading {
  font-size: 28px;
  font-weight: 600;
}
.available-color-flex ul {
  display: flex;
  align-items: center;
}
.available-color-flex {
  display: flex;
  align-items: center;
}

.available-color-flex ul li a {
  height: 28px;
  width: 28px;
  background: #000;
  margin-left: 10px;
  display: block;
}

.available-color-flex {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.available-color-flex ul li.bg-0b50e6 a {
  background: #0b50e6;
}

.available-color-flex ul li.bg-f3bd6c a {
  background: #f3bd6c;
}

.available-color-flex ul li.bg-ff4949 a {
  background: #f3bd6c;
}

.available-color-flex ul li.bg-4f747a a {
  background: #4f747a;
}

.available-color-flex ul li.bg-fff.active a {
  background: #fff;
  width: 40px;
  height: 40px;
  border: 2px solid #0033a1;
}

.available-color-flex h6 {
  font-size: 16px;
  font-weight: 600;
  margin-right: 20px;
}

.available-color-flex ul {
  display: flex;
  align-items: center;
}

.gallery-area {
  padding-top: 100px;
}

.gallery-heading h2 {
  font-size: 55px;
  font-weight: 600;
  color: #1a1a1a;
}

.gallery-heading p {
  font-weight: 500;
}

.gallery-heading {
  margin-bottom: 30px;
}

.gallery-item {
  margin-top: 30px;
  position: relative;
}
.gallery-item a {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: #fff;
  font-size: 28px;
  font-weight: 500;
}

.available-color-flex ul {
  display: flex;
  align-items: center;
}

.gallery-area {
  padding-top: 100px;
}

.gallery-heading h2 {
  font-size: 55px;
  font-weight: 600;
  color: #1a1a1a;
}

.gallery-heading p {
  font-weight: 500;
}

.gallery-heading {
  margin-bottom: 30px;
}

.gallery-item {
  margin-top: 30px;
  position: relative;
}

.gallery-item a {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: #fff;
  font-size: 28px;
  font-weight: 500;
}

.gallery-item-video {
  position: relative;
  padding: 30px 0 0;
}

.videos-area {
  position: relative;
  z-index: 9;
}

.videos-area::after {
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #090a0b;
  z-index: -1;
  content: "";
}

.play-video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

h3.range-video {
  position: absolute;
  top: 20px;
  color: #fff;
  font-weight: 600;
  font-size: 28px;
  left: 20px;
}

.gallery-area ul#pills-tab {
  position: absolute;
  bottom: -10px;
  left: 0px;
  z-index: 9999;
}
.videos-area::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #090a0b;
  content: "";
  opacity: 0.8;
}
.gallery-area .nav-link {
  display: block;
  padding: 0;
}

.gallery-item img,
.gallery-item-video img {
  width: 100%;
}
.gallery-item-video ul#pills-tab {
  display: flex;
  justify-content: space-between;
}

.gallery-item-video ul#pills-tab li {
  width: 19.5%;
}

a.profile-pic {
  width: 62px;
  height: 62px;
  overflow: hidden;
  display: inline-block;
  border-radius: 50%;
  margin-right: 10px;
}

.gallery-2-area {
  background: #fff;
  padding: 30px 15px;
  margin-top: 40px;
}

.gallery-2-area .po-home-cont-top {
  padding: 0;
  position: relative;
}
.gallery-2-area .po-share span img {
  display: block;
  width: 24px;
}

.gallery-2-area .cont-date img {
  width: 16px;
  margin-right: 4px;
}

.gallery-2-area .cont-date {
  display: flex;
  align-items: center;
}

.gallery-2-area .cont-date span {
  font-size: 14px;
}

.gallery-2-area .cont-date img {
  width: 16px;
}

.gallery-2-area img {
  width: 100%;
  display: block;
}

.left-tab-items {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.left-tab-link {
  width: 15%;
}

.left-tab-view {
  width: 84%;
}
.gallery-2-area .nav-link {
  display: block;
  padding: 0 0 10px 0;
}
.gallery-2-area .nav-pills .nav-link.active {
  background-color: transparent;
}

.gallery-2-area .tab-more-photos {
  width: 15%;
  position: relative;
}
.gallery-2-area .tab-more-photos a {
  display: block;
}
.gallery-2-area .po-photo-vi {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.gallery-2-area .po-start-price.col-00af43 p img {
  width: 16px;
}
.gallery-2-area .po-start-price h6 img {
  width: 16px;
  margin-left: 5px;
  cursor: pointer;
}

.gallery-2-area .po-start-price h6 {
  display: flex;
  align-items: center;
}

.gallery-2-area .po-start-prices {
  margin: 0;
}
.gallery-2-area .po-start-card {
  width: 230px;
}
.gallery-2-area .po-start-card .bg-0033a1 {
  padding: 10px 16px;
  margin-right: 0;
  width: 100%;
}
.gallery-2-area .po-start-card button.bg-0033a1 i {
  margin-right: 10px;
}
.gallery-2-area .po-start-price {
  width: 25%;
}

.description-tab-area {
  margin-top: 40px;
}
.description-tab-area .nav-tabs {
  border-bottom: none;
  display: flex;
  justify-content: space-between;
  text-align: center;
  flex-wrap: nowrap;
}

.description-tab-area .nav-tabs .nav-item.show .nav-link,
.description-tab-area .nav-tabs .nav-link.active,
.description-tab-area .nav-tabs .nav-item:hover {
  color: #fff;
  background-color: #0033a1;
  border-color: none;
}

.description-tab-area .nav-tabs .nav-link {
  border: none;
  border-radius: 0;
  font-size: 14px;
  font-weight: 500;
  padding: 0.8rem 1rem;
}
.description-tab-area .nav-tabs .nav-item {
  margin-bottom: 0;
  width: 34%;
  background: #cbcfd1;
}

.description-tab-area .nav-tabs .nav-link:hover {
  color: #fff;
}

.specifications-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.specifications-item {
  width: 30%;
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.specifications-item img {
  width: 40px;
  margin-right: 15px;
}

.specifications-bg {
  background: #fff;
  padding: 0 15px;
}

.specifications-item-cont h6 {
  font-size: 14px;
  font-weight: 500;
}

.specifications-item-cont p {
  font-size: 14px;
  font-weight: 400;
}

h5.engineDetails-headign {
  color: #00a1e0;
  font-size: 16px;
  margin-top: 40px;
}

.specifications-item-cont h6 {
  font-size: 14px;
  font-weight: 500;
}

.specifications-item-cont p {
  font-size: 14px;
  font-weight: 400;
}
.engineDetails-item-right p {
  font-weight: 300;
  font-size: 14px;
}
h5.engineDetails-headign {
  color: #00a1e0;
  font-size: 16px;
  margin: 40px 0 15px;
}

.engineDetails-item {
  display: flex;
  align-items: center;
  padding: 4px 10px;
}
.engineDetails-item:nth-child(odd) {
  background: #f3f4f8;
}

.engineDetails-item-left h5 {
  font-size: 14px;
}
.engineDetails-item-left {
  width: 40%;
}

.engineDetails-item-right {
  display: flex;
  align-items: center;
  width: 60%;
}

.engineDetails-item-right img {
  width: 100px;
}

.color-2d-tw span {
  display: block;
  height: 26px;
  width: 26px;
  background: #000;
  margin-left: 4px;
  cursor: pointer;
}

.color-2d-tw {
  display: flex;
  align-items: center;
  padding: 2px 0;
}

.color-2d-tw p {
  width: 60px;
}

.color-2d-tw span.bg-bd5252 {
  background: #bd5252;
}

.color-2d-tw span.bg-e3e3e3 {
  background: #e3e3e3;
}

.color-2d-tw span.bg-0b50e6 {
  background: #0b50e6;
}

.color-2d-tw span.bg-ffffff {
  background: #fff;
  border: 2px solid;
}

.color-2d-tw span.bg-011623 {
  background: #011623;
}

.color-2d-tw span.bg-051625 {
  background: #051625;
}

.color-2d-tw span.bg-4f747a {
  background: #4f747a;
}

.color-2d-tw span.bg-000000 {
  background: #000000;
}
.price-range-item.range-1 {
  width: 210px;
}
.price-range-item.range-1 input,
.price-range-item.range-2 input {
  width: 126px;
}
.price-range-item.range-2 {
  width: 190px;
}

.price-range-item.range-3 {
  width: 250px;
}
.price-range-item.range-3 input {
  width: 90px;
}
.price-range-cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.primum-igitur {
  margin-top: 30px;
  border-top: 1px solid rgba(124, 134, 141, 0.2);
  padding-top: 30px;
}

.primum-igitur h4 {
  font-size: 22px;
  font-weight: 600;
}

.primum-igitur h5 {
  color: #00a1e0;
  font-size: 16px;
  margin: 20px 0 8px;
}
.primum-igitur p {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.8;
}
.monthly-Payments-area {
  margin-top: 30px;
}
.monthly-Payments-area {
  margin-top: 30px;
  padding: 20px 15px;
  background: #fff;
}

h4.monthly-Payments-hrading {
  font-weight: 600;
}
.price-range-items {
  display: flex;
  justify-content: space-between;
}
.price-range-cont p {
  font-size: 14px;
  font-weight: 600;
}

.price-range-item input {
  width: 60%;
  padding: 5px;
  background: transparent;
  border: solid 2px rgba(26, 26, 26, 0.1);
  font-size: 12px;
  font-weight: 400;
}
.price-range-item.range-1 input {
  background: rgba(124, 134, 141, 0.2);
}

.price-range-item input + input {
  width: 52px !important;
}

.price-ranger {
  position: relative;
  width: 100%;
  height: 2px;
  background: #eff3f9;
}

.price-ranger span {
  position: absolute;
  width: 14px;
  height: 14px;
  background: #00a1e0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
}
.price-range-item {
  margin: 30px 0 0;
}
span.rangepro {
  position: absolute;
  top: 50%;
  height: 14px;
  width: 14px;
  background: #00a1e0;
  right: 0;
  transform: translateY(-50%);
  border-radius: 50%;
}

.price-range-item .progress {
  height: 2px;
  overflow: inherit;
  background-color: #eff3f9;
  border-radius: 0;
  margin-top: 15px;
  margin-bottom: 0;
}

.chart-area {
  padding: 30px 0 0;
  display: flex;
  margin-top: 50px;
}

.chart-content {
  width: 60%;
}

.chart-item span {
  height: 20px;
  width: 20px;
  background: #000;
  display: inline-block;
}

.chart-item {
  display: flex;
  padding: 6px 10px;
}
.chart-item p strong {
  float: right;
}

.chart-item p {
  width: 96%;
  font-size: 14px;
  margin-left: 10px;
}

.chart-item:nth-child(odd) {
  background: #f3f4f8;
}
.chart-item span.bg-00a1e0 {
  background: #00a1e0;
}
.chart-item span.bg-ff4949 {
  background: #ff4949;
}
.chart-item span.bg-7c868d {
  background: #7c868d;
}
.chart-item span.bg-0033a1 {
  background: #0033a1;
}
h3.chart-total {
  text-align: right;
  color: #00af43;
  font-size: 23px;
  font-weight: 600;
  margin-top: 8px;
}

.chart-area button.theme-btn {
  width: 90%;
  border-radius: 0;
  background: #0a42ae;
  margin: 30px 0;
  padding: 10px;
}
.chart-area button.theme-btn:hover {
  background: #00a1e0;
}
.main-chart {
  width: 40%;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

.donut {
  --donut-size: 210px;
  --donut-border-width: 36px;
  --donut-spacing: 0;
  --donut-spacing-color: 255, 255, 255;
  --donut-spacing-deg: calc(1deg * var(--donut-spacing));
  border-radius: 50%;
  height: var(--donut-size);
  position: relative;
  width: var(--donut-size);
  margin: auto;
}

.donut__label {
  left: 50%;
  line-height: 1.5;
  position: absolute;
  text-align: center;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 80%;
}

.donut__label__heading {
  font-size: 24px;
  font-weight: 600;
  color: #ff4949;
}

.donut__label__sub {
  color: rgba(124, 134, 141, 0.8);
  font-size: 10px;
}

.donut__slice {
  height: 100%;
  position: absolute;
  width: 100%;
}

.donut__slice::before,
.donut__slice::after {
  border: var(--donut-border-width) solid rgba(0, 0, 0, 0);
  border-radius: 50%;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 100%;
}

.donut__slice::before {
  border-width: calc(var(--donut-border-width) + 1px);
  box-shadow: 0 0 1px 0
    rgba(var(--donut-spacing-color), calc(100 * var(--donut-spacing)));
}

.donut__slice__first {
  --first-start: 0;
}

.donut__slice__first::before {
  border-top-color: rgba(
    var(--donut-spacing-color),
    calc(100 * var(--donut-spacing))
  );
  -webkit-transform: rotate(calc(360deg * var(--first-start) + 45deg));
  transform: rotate(calc(360deg * var(--first-start) + 45deg));
}

.donut__slice__first::after {
  border-top-color: #0033a1;
  border-right-color: rgba(0, 51, 161, calc(100 * (var(--first) - 0.25)));
  border-bottom-color: rgba(0, 51, 161, calc(100 * (var(--first) - 0.5)));
  border-left-color: rgba(0, 51, 161, calc(100 * (var(--first) - 0.75)));
  -webkit-transform: rotate(
    calc(360deg * var(--first-start) + 45deg + var(--donut-spacing-deg))
  );
  transform: rotate(
    calc(360deg * var(--first-start) + 45deg + var(--donut-spacing-deg))
  );
}

.donut__slice__second {
  --second-start: calc(var(--first));
  --second-check: max(calc(var(--second-start) - 0.5), 0);
  -webkit-clip-path: inset(
    0 calc(50% * (var(--second-check) / var(--second-check))) 0 0
  );
  clip-path: inset(
    0 calc(50% * (var(--second-check) / var(--second-check))) 0 0
  );
}

.donut__slice__second::before {
  border-top-color: rgba(
    var(--donut-spacing-color),
    calc(100 * var(--donut-spacing))
  );
  -webkit-transform: rotate(calc(360deg * var(--second-start) + 45deg));
  transform: rotate(calc(360deg * var(--second-start) + 45deg));
}

.donut__slice__second::after {
  border-top-color: #00a1e0;
  border-right-color: rgba(0, 161, 224, calc(100 * (var(--second) - 0.25)));
  border-bottom-color: rgba(0, 161, 224, calc(100 * (var(--second) - 0.5)));
  border-left-color: rgba(0, 161, 224, calc(100 * (var(--second) - 0.75)));
  -webkit-transform: rotate(
    calc(360deg * var(--second-start) + 45deg + var(--donut-spacing-deg))
  );
  transform: rotate(
    calc(360deg * var(--second-start) + 45deg + var(--donut-spacing-deg))
  );
}

.donut__slice__third {
  --third-start: calc(var(--first) + var(--second));
  --third-check: max(calc(var(--third-start) - 0.5), 0);
  -webkit-clip-path: inset(
    0 calc(50% * (var(--third-check) / var(--third-check))) 0 0
  );
  clip-path: inset(0 calc(50% * (var(--third-check) / var(--third-check))) 0 0);
}

.donut__slice__third::before {
  border-top-color: rgba(
    var(--donut-spacing-color),
    calc(100 * var(--donut-spacing))
  );
  -webkit-transform: rotate(calc(360deg * var(--third-start) + 45deg));
  transform: rotate(calc(360deg * var(--third-start) + 45deg));
}

.donut__slice__third::after {
  border-top-color: #c6cacc;
  border-right-color: rgba(188, 203, 205, calc(100 * (var(--third) - 0.25)));
  border-bottom-color: rgba(188, 203, 205, calc(100 * (var(--third) - 0.5)));
  border-left-color: rgba(188, 203, 205, calc(100 * (var(--third) - 0.75)));
  -webkit-transform: rotate(
    calc(360deg * var(--third-start) + 45deg + var(--donut-spacing-deg))
  );
  transform: rotate(
    calc(360deg * var(--third-start) + 45deg + var(--donut-spacing-deg))
  );
}

.donut__slice__fourth {
  --fourth-start: calc(var(--first) + var(--second) + var(--third));
  --fourth-check: max(calc(var(--fourth-start) - 0.5), 0);
  -webkit-clip-path: inset(
    0 calc(50% * (var(--fourth-check) / var(--fourth-check))) 0 0
  );
  clip-path: inset(
    0 calc(50% * (var(--fourth-check) / var(--fourth-check))) 0 0
  );
}

.donut__slice__fourth::before {
  border-top-color: rgba(
    var(--donut-spacing-color),
    calc(100 * var(--donut-spacing))
  );
  -webkit-transform: rotate(calc(360deg * var(--fourth-start) + 45deg));
  transform: rotate(calc(360deg * var(--fourth-start) + 45deg));
}

.donut__slice__fourth::after {
  border-top-color: #ff5500;
  border-right-color: rgba(225, 85, 0, calc(100 * (var(--fourth) - 0.25)));
  border-bottom-color: rgba(225, 85, 0, calc(100 * (var(--fourth) - 0.5)));
  border-left-color: rgba(225, 85, 0, calc(100 * (var(--fourth) - 0.75)));
  -webkit-transform: rotate(
    calc(360deg * var(--fourth-start) + 45deg + var(--donut-spacing-deg))
  );
  transform: rotate(
    calc(360deg * var(--fourth-start) + 45deg + var(--donut-spacing-deg))
  );
}

.our-contact-area {
  background: #fff;
  padding: 30px 15px;
  margin-top: 40px;
}

.profile-heading {
  display: flex;
  align-items: flex-start;
  position: relative;
}

.profile-cont h5,
.profile-cont h6 {
  font-size: 15px;
}

.profile-cont h6 {
  font-weight: 400;
}

.profile-cont p {
  font-size: 10px;
  color: rgba(124, 134, 141, 0.8);
}

.profile-heading .profile-cont + a {
  text-align: right;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 10px;
  font-weight: 600;
  color: #0aa0e4;
}

a.profile-pic {
  width: 62px;
  height: 62px;
  overflow: hidden;
  display: inline-block;
  border-radius: 50%;
  margin-right: 10px;
}

.profile-heading .profile-cont + a {
  text-align: right;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 10px;
  font-weight: 600;
  color: #0aa0e4;
}

.profile-up input.theme-btn.bg-0033A1 {
  margin-top: 0;
  border-radius: 0;
  border: 2px solid #0033a1;
  background: #0033a1;
}

.profile-up input.theme-btn.bg-0033A1:hover {
  border: 2px solid #0aa0e4;
  background: #0aa0e4;
}

h6.contact-agent-heading {
  padding: 30px 0 15px;
}

.similar-car-cont {
  margin: 40px 0;
}

.similar-car-cont h4 {
  font-size: 23px;
  font-weight: 600;
  margin-bottom: 10px;
}

.similar-car-cont p {
  font-size: 13px;
}

/*
.similar-cars-area {
    max-width: 1666px;
    margin: auto;
}
*/

.similar-cars-area .po-car-item {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.07);
  margin: 20px 8px;
}

.similar-cars-area .po-start-price.col-00af43 {
  border-right: 0;
}

.similar-cars-area .po-home-item-headitop p img {
  width: 16px !important;
}

.similar-cars-area .po-share span img {
  width: 26px !important;
}

.similar-cars-area .po-start-price {
  width: 40%;
}

.similar-cars-area .po-start-price p img {
  width: 16px !important;
}

.similar-cars-area .po-start-prices {
  position: relative;
}

.similar-cars-area .owl-item {
  opacity: 0.2;
}

.similar-cars-area .owl-item.active {
  opacity: 1;
}

.similar-cars-area .po-start-prices::after {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  height: 100%;
  width: 2px;
  background: #e5e7e8;
  content: "";
  opacity: 0.4;
}

.similar-cars-area .owl-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.similar-cars-area button.owl-next {
  position: absolute;
  right: 8%;
  height: 40px;
  width: 40px;
  background: #0033a1 !important;
  color: #fff !important;
}

.similar-cars-area button.owl-prev {
  position: absolute;
  left: 8%;
  height: 40px;
  width: 40px;
  background: #0033a1 !important;
  color: #fff !important;
}

/*~~~~~~~~~~~~~~~~~~  0000 Landing Page Start ~~~~~~~~~~~~~~~~~~ */

.landing-page-slider-item {
  z-index: 99;
  position: relative;
}

.landing-page-slider-item::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  content: "";
  z-index: 1;
  opacity: 0.3;
}
.landing-page-slider-content {
  position: absolute;
  bottom: 150px;
  z-index: 99;
}
.landing-page-slider-item p,
.landing-page-slider-item h2 {
  color: #fff;
}

.landing-page-slider-item h2 {
  font-size: 55px;
  font-weight: 600;
}

.landing-page-slider-item p {
  font-weight: 500;
  font-size: 23px;
  letter-spacing: 1px;
  padding: 10px 0 30px;
}

.landing-page-slider-area .owl-dots {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: 60px;
}

.landing-page-slider-area .owl-dots .indicators.active .owl-dot.active span,
.owl-dots .owl-dot:hover span {
  background: #00a1e0;
}

.landing-page-slider-area .owl-dots .owl-dot .indicators span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: rgba(124, 134, 141, 0.4);
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 0.2s ease;
  border-radius: 1px;
}

.landing-page-slider-item .theme-btn {
  border-radius: 0;
  max-width: 320px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}

.landing-page-slider-item .theme-btn:hover,
.visit-our-mar-cont .theme-btn:hover {
  background: #0033a1;
  color: #fff;
}

.visit-our-mar-area {
  padding: 60px 0;
}

h3.allheading-con {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
}

.visit-our-mar-heading p {
  font-size: 18px;
}

.visit-our-mar-item {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  min-height: 320px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 30px;
  padding-left: 30px;
  position: relative;
  z-index: 99;
  margin-top: 30px;
}

.visit-our-mar-item::after {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #000;
  content: "";
  z-index: -1;
  opacity: 0.5;
}

.visit-our-mar-cont h4,
.visit-our-mar-cont p {
  color: #fff;
}

.visit-our-mar-cont h4 {
  font-weight: 500;
}

.visit-our-mar-cont p {
  font-size: 14px;
  font-weight: 500;
  margin: 5px 0 20px;
}

.visit-our-mar-cont .theme-btn {
  border-radius: 0;
  font-size: 14px;
  min-width: 130px;
  text-align: center;
  font-weight: 500;
  padding: 12px;
}

.visit-our-mar-cont .theme-btn {
  border-radius: 0;
  font-size: 14px;
  min-width: 130px;
  text-align: center;
  font-weight: 500;
  padding: 12px;
}

.want-sell-area {
  padding: 60px 0;
}

.want-sell-cont-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.landing-houses-area h3.allheading-con {
  margin-bottom: 0;
}

.want-sell-cont h3 {
  color: #0aa0e4;
  font-size: 28px;
  font-weight: 600;
}

.want-sell-cont p {
  color: #0aa0e4;
  font-size: 20px;
}

.want-sell-btn .theme-btn {
  padding: 14px 80px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 0;
}

.want-sell-btn .theme-btn:hover {
  color: #fff;
}

.next-move-items {
  display: flex;
  justify-content: space-between;
}

.next-move-item {
  text-align: center;
  background: #fff;
  width: 24%;
  margin-top: 100px;
}

.next-move-items {
  display: flex;
  justify-content: space-between;
}

.next-move-item {
  text-align: center;
  background: #fff;
  width: 24%;
  margin-top: 100px;
  box-shadow: 0 3px 15px -1px rgba(0, 0, 0, 0.07);
}

.next-move-top {
  width: 64px;
  height: 64px;
  margin: auto;
  background: #fff;
  border-radius: 50%;
  overflow: hidden;
  margin-top: -50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.next-move-top img {
  width: 40px;
}

.next-move-cont {
  padding: 30px 60px 40px;
}

.next-move-top.bg-0033a1:hover {
  background: #0033a1;
}

.next-move-item.bg-grd {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#0a2240+0,003d94+51,00a1e0+100 */
  background: #0a2240;
  /* Old browsers */
  background: -moz-linear-gradient(top, #0a2240 0%, #003d94 51%, #00a1e0 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #0a2240 0%,
    #003d94 51%,
    #00a1e0 100%
  );
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #0a2240 0%, #003d94 51%, #00a1e0 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0a2240', endColorstr='#00a1e0', GradientType=0);
  /* IE6-9 */
}

.next-move-cont h4,
.next-move-cont p {
  color: #0033a1;
}

.next-move-cont h4 {
  font-weight: 600;
}

.bg-grd .next-move-cont h4,
.bg-grd .next-move-cont p {
  color: #fff;
}

.next-move-cont h4 {
  margin-bottom: 20px;
  font-size: 23px;
}

.marketplace-area {
  padding: 60px 0;
}

.marketplace-cont {
  background: #0a2240;
  background: -moz-linear-gradient(left, #0a2240 0%, #003d94 51%, #00a1e0 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #0a2240 0%,
    #003d94 51%,
    #00a1e0 100%
  );
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #0a2240 0%, #003d94 51%, #00a1e0 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0a2240', endColorstr='#00a1e0', GradientType=1);
  /* IE6-9 */

  text-align: center;
  padding: 78px 0;
}

.marketplace-cont h4,
.marketplace-cont p {
  color: #fff;
}

.marketplace-cont p {
  margin: 12px 0 30px;
  font-size: 18px;
}

.marketplace-cont .theme-btn {
  color: #000;
  font-size: 14px;
  border-radius: 0;
  padding: 12px 70px;
  font-weight: 500;
}

.bg-fff {
  background: #fff;
}

.bg-00a1e0 {
  background: #00a1e0;
}

.bg-0a2240 {
  background: #0a2240;
}

.bg-0033a1 {
  background: #0033a1;
}

.bg-00a860 {
  background: #00a860;
}

.subscribe-newsletter-cont {
  background-image: linear-gradient(104deg, #003d94 4%, #00af43 139%);
  text-align: center;
  padding: 80px 0;
}

.subscribe-newsletter-cont h4,
.subscribe-newsletter-cont p,
.subscribe-newsletter-cont a,
.tips-information-item h4,
.tips-information-item p,
.tips-information-item a {
  color: #fff;
}

.subscribe-newsletter-cont p {
  font-size: 18px;
  margin: 15px 0 50px;
  font-weight: 300;
}

.subscribe-newsletter-cont a {
  background: #fff;
  color: #000;
  max-width: 330px;
  width: 100%;
  display: inline-block;
  padding: 14px;
  font-weight: 600;
  font-size: 14px;
}
.subscribe-newsletter-cont a:hover {
  background: #38b6ff;
  color: #fff;
}

.tips-information-item {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  padding: 30px;
  height: 375px;
  display: flex;
  align-items: flex-end;
  margin-top: 20px;
}

.tips-information-item.tips-information-big {
  position: relative;
  z-index: 99;
}

.tips-information-item.tips-information-big::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  content: "";
  opacity: 0.5;
  z-index: -1;
}

.subscribe-newsletter-area {
  padding: 60px 0;
}

.tips-cont-ar p {
  margin: 15px 0 40px;
  font-size: 18px;
  font-weight: 300;
}

.tips-information-item a {
  font-weight: 500;
  font-size: 14px;
}

.tips-information-item a:hover {
  color: #0033a1;
}

/*~~~~~~~~~~~~~~~~~~  0000 Landing Page Start ~~~~~~~~~~~~~~~~~~ */

.donut__custom {
  --first: 0.4;
  --second: 0.4;
  --third: 0.1;
  --fourth: 0.1;
  --donut-spacing: 0;
}

.darken_plus_images {
  filter: brightness(50%);
}
/*~~~~~~~~~~~~~~~~~~  11 Photo Full View Start ~~~~~~~~~~~~~~~~~~ */
.joinus-sub-menu {
  position: relative;
}
.joinus-sub-menu ul.join-sub {
  position: absolute;
  top: 100%;
  width: 150px;
  background: #fff;
  left: 50%;
  transform: translate(-50%);
}

ul.join-sub {
  position: absolute;
  background: #fff;
  padding: 30px 0 10px;
  opacity: 0;
  visibility: hidden;
}

ul.join-sub li a {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.joinus-sub-menu:hover .joinus {
  background: #00a1e0;
  color: #fff;
}
.joinus-sub-menu li:hover,
.joinus-sub-menu li.active {
  background: #00a1e0;
}

.joinus-sub-menu ul.join-sub li:hover a,
.joinus-sub-menu ul.join-sub li:hover i,
.joinus-sub-menu ul.join-sub li.active a,
.joinus-sub-menu ul.join-sub li.active i {
  color: #fff;
}
/*
.joinus-sub-menu  li a:hover{
    color: #fff
}
*/

.joinus-sub-menu ul.join-sub li a {
  padding: 6px 0;
  color: #000;
  display: flex;
  align-items: center;
  line-height: 2;
  font-size: 14px;
  font-weight: 400;
}
.joinus-sub-menu ul.join-sub li a i {
  font-size: 20px;
  margin-right: 10px;
}

.joinus-sub-menu:hover .join-sub {
  opacity: 1;
  visibility: visible;
}

.cart-menu-dropdown {
  position: absolute;
  background: #fff;
  width: 330px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
  box-shadow: 0 69px 300px 0 rgba(0, 0, 0, 0.28);
  z-index: -1;
}
.card-menu-items {
  position: relative;
}

.cart-headig {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 20px 15px;
  border-bottom: 1px solid #aabbcd;
}
.cart-headig h6 {
  color: #aabbcd;
  font-size: 15px;
}
a.add-to-item {
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin: 20px 0;
}
a.add-to-item .add-to-img {
  margin-right: 10px;
  width: 60px;
  height: 60px;
  overflow: hidden;
}
a.add-to-item .add-to-img img {
  width: 100%;
}
.cart-menu-dropdown .cart-headig a {
  padding: 0;
  margin: 0;
  color: #00a1e0 !important;
  font-size: 15px;
  font-weight: 600;
}

a.add-to-item .add-to-cont p {
  font-size: 13px;
  line-height: 1.3;
  margin-bottom: 7px;
}
a.add-to-item .add-to-cont {
  padding-right: 12px;
}
a.add-to-item .add-to-cont h6 {
  color: #00af43;
  font-weight: 600;
  font-size: 14px;
}
.add-to-number {
  height: 24px;
  width: 25px;
  background: #7c868d;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-to-number p {
  font-size: 11px;
  color: #fff;
}

button.checkout-btn i {
  margin-left: 20px;
}
button.checkout-btn {
  width: 100%;
  border: none;
  padding: 12px;
  background: #00a860;
  color: #fff;
  font-size: 16px;
}
button.checkout-btn:hover {
  background: #0aa0e4;
}

.card-menu-items:hover .cart-menu-dropdown {
  opacity: 1;
  visibility: visible;
}

.empty-add-carts {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 200px;
  margin: 50px auto;
}
.empty-content p {
  margin-top: 10px;
}

button.checkout-btn.empty-cart {
  background: #c9cacf;
}

.modal {
  z-index: 9999999;
}
.modal-backdrop {
  opacity: 0.7 !important;
  z-index: 999999 !important;
}

.photo-view-slider-area .modal-dialog {
  max-width: 1200px;
  margin: 1.75rem auto;
}
.photo-view-slider-area .modal-content {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #000 !important;
}

.photo-view-slider-area .modal-header {
  border-bottom: none;
  padding: 1rem 1rem 0;
}
.photo-view-slider-area .modal-body {
  padding: 1rem 0 0;
}
.photo-view-slider-area .modal-header .close {
  position: absolute;
  right: 39%;
  float: right;
  font-size: 30px;
  font-weight: 400;
  line-height: 1;
  color: #fff;
  text-shadow: none;
  opacity: 1;
  padding: 0;
  margin: 0;
}
.photo-view-slider-area .modal-header .close span {
  color: #fff;
}
.all-add-carts {
  margin-bottom: 30px;
}

.photo-pop-box-area {
  display: flex;
  justify-content: space-between;
}

.photo-view-slider-area h5#exampleModalLongTitle {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
}

.photo-view-slider-area h5#exampleModalLongTitle img {
  margin-right: 30px;
}

.photo-view-slider-area h5#exampleModalLongTitle span {
  color: #fff;
}

.photo-pop-box-slder {
  width: 64%;
}
.photo-view-form {
  width: 34%;
  background: #fff;
  padding: 15px;
  position: relative;
  margin-top: -62px;
}

.photo-pop-box-slder ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap !important;
}
.photo-pop-box-slder li.nav-item {
  width: 100%;
  text-align: center;
}
.photo-view-slider-area .nav-pills .nav-link {
  border-radius: 0;
  color: #5b7082;
  font-weight: 500;
  border-bottom: 3px solid transparent;
  padding: 1rem 0.5rem;
}
.photo-view-slider-area .nav-pills .nav-link.active,
.photo-view-slider-area .nav-pills .show > .nav-link {
  color: #fff;
  background-color: transparent;
  border-bottom: 3px solid #00a1e0;
}

#sync1 .item {
  background: #0c83e7;
  color: #fff;
  border-radius: 3px;
  text-align: center;
}
#sync2 .item {
  background: #000;
  padding: 2px;
  margin: 20px 2px 0;
  color: #fff;
  border-radius: 0;
  text-align: center;
  cursor: pointer;
  position: relative;
  z-index: 999;
}
#sync2 .item h1 {
  font-size: 18px;
}

.owl-theme .owl-nav {
  /*default owl-theme theme reset .disabled:hover links */
}
.owl-theme .owl-nav [class*="owl-"] {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.owl-theme .owl-nav [class*="owl-"].disabled:hover {
  background-color: #d6d6d6;
}
#sync1.owl-theme {
  position: relative;
}
#sync1.owl-theme .owl-next,
#sync1.owl-theme .owl-prev {
  width: 22px;
  height: 30px;
  margin-top: -20px;
  position: absolute;
  top: 50%;
}
#sync1.owl-theme .owl-prev {
  left: 10px;
}
#sync1.owl-theme .owl-next {
  right: 10px;
}
.owl-carousel .owl-stage {
  margin: 0 auto;
}

#sync2 .current .item {
  background: #fff;
}
#sync2 .current .item::after {
  position: absolute;
  top: -6px;
  left: 50%;
  height: 14px;
  width: 14px;
  background: #fff;
  content: "";
  z-index: -1;
  transform: translate(-50%) rotate(45deg);
}

/*~~~~ From Area   ~~~~~~*/
.photo-view-profile {
  display: flex;
  align-items: center;
  position: relative;
}

.photo-profile-content h5 {
  font-size: 16px;
}

.photo-profile-content h6 {
  font-size: 13px;
  margin-top: 5px;
}

.photo-profile-content p {
  font-size: 10px;
}

a.view-profile-pho {
  position: absolute;
  right: 0;
  top: 5px;
  font-size: 12px;
  color: #00a1e0;
}

.photo-profile-img {
  margin-right: 10px;
}

h4.photo-heading {
  font-size: 18px;
  margin: 20px 0 20px;
}

.photo-pop-box-area .input-box input.theme-btn {
  padding: 10px 15px;
  border: 2px solid transparent;
  font-size: 14px;
  border-radius: 2px;
}
.photo-pop-box-area .seetoo {
  color: #00a1e0;
  text-align: center;
  display: block;
  margin-top: 20px;
  font-weight: 500;
  cursor: pointer;
}

.photo-count {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0 5px;
}

.photo-count h6 {
  color: #fff;
  margin-left: 10px;
  font-size: 14px;
}
.fullscreen-view-img {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 20px;
  cursor: pointer;
}

/*
.frist-block{
    display: none
}
*/

.photo-view-form .pagamento-Mensal .price-range-items,
.photo-view-form .pagamento-Mensal .chart-area {
  display: block;
}
.photo-view-form .pagamento-Mensal .price-range-item,
.photo-view-form .pagamento-Mensal .chart-content,
.photo-view-form .pagamento-Mensal .chart-area button.theme-btn {
  width: 100% !important;
}
.photo-view-form .pagamento-Mensal .chart-area {
  margin-top: 20px;
}

.photo-view-form .pagamento-Mensal .monthly-Payments-area {
  margin-top: 0;
  padding: 0;
}
.photo-view-form .pagamento-Mensal .chart-item span.bg-00a860 {
  background: #00a860;
}
.photo-view-form .pagamento-Mensal .chart-item span.bg-ffffff {
  background: #ffffff;
}

.photo-view-form .pagamento-Mensal .donut {
  --donut-size: 100px;
  --donut-border-width: 16px;
}

.photo-view-form .pagamento-Mensal .donut__label__heading {
  font-size: 14px;
  font-weight: 600;
  color: #ff4949;
}
.photo-view-form .pagamento-Mensal .donut__label__sub {
  font-size: 8px;
  line-height: 1;
}

.photo-view-form .pagamento-Mensal .donut__slice__fourth::after {
  border-top-color: #00a860;
}

.photo-view-form .pagamento-Mensal h3.chart-total {
  text-align: left;
  margin-top: 0;
}

.photo-view-form .pagamento-Mensal h3.chart-total span {
  font-size: 15px;
  color: #00af43;
}
.photo-view-form .pagamento-Mensal .text-and-chart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 35px;
}
.photo-view-form .pagamento-Mensal .main-chart {
  width: inherit;
}
.photo-view-form .pagamento-Mensal .chart-area button.theme-btn.bg-0033a1 {
  font-size: 16px;
}

.photo-view-form .pagamento-Mensal button.send-agent {
  background: transparent;
  border: none;
  margin: auto;
  display: block;
  color: #00a1e0;
  font-size: 14px;
  font-weight: 600;
  padding: 0;
}
.photo-view-form .pagamento-Mensal button.send-agent:hover,
.photo-pop-box-area .seetoo:hover {
  color: #0033a1;
}
.pagamento-Mensal {
  display: none;
}
.photo-view-form.next-form .photo-view-form-item {
  display: none;
}
.photo-view-form.next-form .pagamento-Mensal {
  display: block;
}
h6.simulador-heading {
  font-size: 18px;
  font-weight: 600;
  margin-top: 5px;
}

.filter-menu-warp .modal-dialog {
  max-width: 780px;
}

.filter-menu-items div#nav-tab {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.filter-menu-items .nav-tabs {
  border-bottom: none;
}

.filter-menu-warp .modal-body {
  padding: 0;
}
.filter-menu-warp button.close {
  position: absolute;
  right: 15px;
  font-size: 18px;
  z-index: 999999999;
}
.filter-menu-items div#nav-tab a.nav-item {
  width: 100%;
}
.filter-menu-area {
  display: flex;
  justify-content: space-between;
}

.filter-menu-items {
  width: 29%;
  height: 500px;
  overflow-y: scroll;
}

.filter-menu-details {
  width: 69%;
  height: 500px;
  overflow-y: scroll;
}

.filter-menu-warp .nav-tabs .nav-link {
  border: none;
}
.filter-menu-warp .filter-menu-items nav a.nav-link {
  padding: 10px 15px;
  position: relative;
  border-radius: 0;
}
.filter-menu-warp .filter-menu-items nav a.nav-link:after {
  border-bottom: 1px solid #6f757b;
  border-right: 1px solid #6f757b;
  content: "";
  display: block;
  height: 8px;
  pointer-events: none;
  position: absolute;
  right: 20px;
  top: 50%;
  transition: all 0.15s ease-in-out;
  width: 8px;
  transform: translateY(-50%) rotate(-45deg);
}
.filter-menu-warp .filter-menu-items nav a.nav-link.active:after {
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
}
h6.filter-menu-heading {
  margin: 20px 0;
  color: #00a1e0;
}

.filter-menu-warp .nav-tabs .nav-item.show .nav-link,
.filter-menu-warp .filter-menu-items nav a.nav-link.active {
  color: #fff;
  background-color: #0033a1;
  border-color: #dee2e6 #dee2e6 #fff;
  font-weight: 500;
}

.filter-menu-warp .filter-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px;
  box-shadow: 2px 14px 30px 0 rgba(0, 0, 0, 0.1),
    2px 1px 12px 0 rgba(0, 0, 0, 0.03);
}

.filter-menu-warp .fl-clears {
  flex-wrap: wrap;
  justify-content: flex-start;
}

.filter-menu-warp .fl-clear {
  margin: 5px 5px 0 0;
}

.filter-menu-warp .filter-bot-src {
  width: 20%;
}

.filter-menu-warp .fl-items-all {
  width: 80%;
}

.filter-menu-warp .fl-src p {
  font-size: 14px;
}
.filter-menu-warp .fl-src {
  margin: 0;
  cursor: pointer;
  padding: 12px;
}

.filter-menu-warp .fl-src img {
  width: 14px;
}
.filter-menu-warp .fl-clear-all h6 {
  font-size: 10px;
}
.filter-menu-warp .fl-clear-all {
  margin-left: 0;
  display: flex;
  margin-top: 10px;
  align-items: center;
}
.filter-menu-warp .fl-clear-all a {
  color: #ff4949;
  text-decoration: underline;
  margin-left: 11px;
  font-size: 10px;
  font-weight: 600;
}

/* width */
.filter-menu-details::-webkit-scrollbar,
.filter-menu-items::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.filter-menu-details::-webkit-scrollbar-track,
.filter-menu-items::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.filter-menu-details::-webkit-scrollbar-thumb,
.filter-menu-items::-webkit-scrollbar-thumb {
  background: #7c868d;
}

/* Handle on hover */
.filter-menu-details::-webkit-scrollbar-thumb:hover,
.filter-menu-items::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Base for label styling */
.filter-menu-details [type="checkbox"]:not(:checked),
.filter-cheack-items [type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}
.filter-cheack-items [type="checkbox"]:not(:checked) + label,
.filter-cheack-items [type="checkbox"]:checked + label {
  position: relative;
  padding-left: 1.95em;
  cursor: pointer;
}

/* checkbox aspect */
.filter-cheack-items [type="checkbox"]:not(:checked) + label:before,
.filter-cheack-items [type="checkbox"]:checked + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 4px;
  width: 1.25em;
  height: 1.25em;
  border: 2px solid rgba(26, 26, 26, 0.2);
  background: #fff;
  border-radius: 0;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* checked mark aspect */
.filter-cheack-items [type="checkbox"]:not(:checked) + label:after,
.filter-cheack-items [type="checkbox"]:checked + label:after {
  content: "\2713\0020";
  position: absolute;
  top: 3px;
  left: 0;
  font-size: 18px;
  line-height: 15px;
  color: #fff;
  transition: all 0.2s;
  font-family: "Lucida Sans Unicode", "Arial Unicode MS", Arial;
  background: #0033a1;
  padding: 3px;
}
/* checked mark aspect changes */
.filter-cheack-items [type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.filter-cheack-items [type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

.filter-material-items ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.filter-material-items ul li {
  width: 26%;
  margin-bottom: 40px;
  position: relative;
  cursor: pointer;
}

.filter-material-items ul li .choice-color {
  height: 24px;
  width: 24px;
  display: block;
  position: absolute;
  top: 8px;
  left: 8px;
  background: #fff;
}
.filter-material-items ul li.choice-color-active .choice-color::after {
  position: absolute;
  top: -5px;
  left: 3px;
  content: "\2713";
  z-index: 999;
  color: #0033a1;
  font-size: 20px;
  font-weight: 700;
}

.color-interior-filter-item {
  width: 26px;
  height: 26px;
  margin-bottom: 10px;
  position: relative;
  z-index: 9;
  cursor: pointer;
}

.color-interior-filter-item.color-interior-filter-item-active::before {
  width: 32px;
  height: 32px;
  position: absolute;
  top: -4px;
  left: -4px;
  content: "";
  z-index: 1;
  border: 4px solid #398fee;
}

.color-interior-filter-item.bg-bd5252 {
  background: #bd5252;
}

.color-interior-filter-item.bg-e3e3e3 {
  background: #e3e3e3;
}

.color-interior-filter-item.bg-000000 {
  background: #000000;
}

.color-interior-filter-item.bg-0b50e6 {
  background: #0b50e6;
}

.color-interior-filter-item.bg-f2f2f2 {
  background: #f2f2f2;
}

.color-interior-filter-item.bg-011623 {
  background: #011623;
}
.color-interior-filter-item.bg-051625 {
  background: #051625;
}
.color-interior-filter-item.bg-4f747a {
  background: #4f747a;
}
.color-interior-filter-item.bg-53585c {
  background: #53585c;
}
.color-interior-filter-item.bg-000000 {
  background: #000000;
}

.color-interior-filter-area {
  display: flex;
}

.color-interior-filters {
  display: flex;
}

.color-interior-filter {
  margin: 0 30px 0 10px;
}

.filter-size-items {
  display: flex;
  margin-top: 25px;
}
.filter-size-items .fl-labels {
  width: 30%;
}

.filter-size-items .fl-labels .fl-form {
  width: 100%;
}

.filter-size-items input {
  width: 100%;
  border: 2px solid rgba(26, 26, 26, 0.7);
  padding: 6px 2px 6px 5px;
  font-size: 14px;
}
.filter-size-items .fl-form p {
  top: -18px;
  font-size: 12px;
  color: #0aa0e4;
}
.filter-size-items .fl-price {
  width: 60%;
}
.filter-size-items .fl-price-range {
  width: 100%;
}
.filter-size-items span.fl-price-range-line {
  width: 100%;
}
.filter-size-items span.fl-price-range-left {
  left: 17%;
}
.filter-size-items span.fl-price-range-right {
  right: 4%;
}
/*~~~~ From Area   ~~~~~~*/
/*~~~~~~~~~~~~~~~~~~  11 Photo Full View End ~~~~~~~~~~~~~~~~~~ */
/*~~~~~~~~~~~~~~~~~~  5section page Start ~~~~~~~~~~~~~~~~~~ */
.menu-profile {
  padding: 26px 60px;
  cursor: pointer;
}

.menu-profile-sm img {
  width: 30px;
  border-radius: 50%;
  margin-right: 5px;
}

.menu-profile-sm {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
}

.menu-profile {
  position: relative;
}

.profile-menu-dropdown {
  position: absolute;
  background: #fff;
  left: 0;
  visibility: hidden;
  opacity: 0;
  box-shadow: 0 69px 300px 0 rgba(0, 0, 0, 0.28);
  z-index: -1;
}

.profile-menu-heading {
  display: flex;
  align-items: center;
  padding: 50px 43px 16px 15px;
  border-bottom: 1px solid #979797;
}

.profile-menu-img img {
  width: 46px;
  border-radius: 50%;
}

.profile-menu-img {
  margin-right: 10px;
}

.profile-menu-cont p {
  font-size: 12px;
}

.profile-menu-cont h5 {
  font-size: 22px;
  font-weight: 600;
  line-height: 1;
}

.profile-menu-a a {
  display: block;
  padding: 10px 16px;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
  transition: all 0.3s ease;
}

.profile-menu-a a.active,
.profile-menu-a a:hover {
  background: #00a1e0;
  color: #fff;
}

.profile-menu-a a:hover i,
.profile-menu-a a.active i {
  color: #fff;
}

.profile-menu-a a i {
  margin-right: 15px;
}

.profile-menu-a {
  padding: 15px 0;
}

.menu-profile:hover .profile-menu-dropdown {
  opacity: 1;
  visibility: visible;
}

.page-wrap {
  max-width: 1430px;
  margin: auto;
  padding: 0 15px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  position: relative;
}
/*~~~~~~~~ Left Menu ~~~~~~~~*/

.left-menu-area {
  width: 250px;
  background: #fff;
  padding: 30px 22px;
  position: relative;
  margin-right: 20px;
}

.left-profile-headign img {
  border-radius: 50%;
  width: 66px;
  height: 66px;
}

.left-profile-headign a {
  display: block;
}

.left-profile-headign a h4 {
  font-size: 22px;
  font-weight: 600;
  margin: 10px 0;
}

.left-profile-headign a p {
  font-size: 14px;
}

a.profile-edit-details {
  color: #00a1e0;
  font-weight: 600;
}

.left-profile-headign {
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(165, 165, 165, 0.4);
  padding-bottom: 20px;
}

.left-main-menu-item {
  min-height: 100px;
  margin-top: 30px;
}

.bg-select-all-product h5 {
  color: #0033a1;
  margin-left: 15px;
  font-weight: 400;
}

h6.left-main-menu-item-heading {
  font-size: 12px;
  font-weight: 400;
  color: #0033a1;
  padding-bottom: 8px;
}

.left-main-menu-item ul li {
  margin: 18px 0;
}

.left-main-menu-item ul li a {
  font-size: 16px;
  font-weight: 600;
  display: block;
}

.left-main-menu-item ul li a.active,
.left-main-menu-item ul li a:hover {
  color: #00a1e0;
}

.left-main-menu-item ul li a i {
  margin-right: 10px;
  font-size: 20px;
}

.big-page-area {
  width: 84%;
  margin-bottom: 60px;
}

.mobile-menu-icon {
  background: #00a860;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -30px;
  display: none;
  top: 0px;
  color: #fff;
}

.page-heading-top h5 {
  font-size: 22px;
  font-weight: 600;
}

ul.page-link-big {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

ul.page-link-big li a {
  margin-right: 20px;
  display: block;
  font-size: 14px;
  color: rgba(124, 134, 141, 0.8);
}

ul.page-link-big li a i {
  font-size: 13px;
  margin-left: 4px;
}

ul.page-link-big li span {
  color: #00a1e0;
  font-weight: 600;
}

.big-select-product-item-top-flex {
  background: #e7e7e7;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 12px;
}

.big-select-product-item-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 12px;
}

.big-rem-pro-item span {
  margin-left: 15px;
}

.big-rem-pro-item {
  display: flex;
  margin-left: 50px;
}

.big-rem-pro-item span {
  margin-left: 15px;
}

.big-se-pro-item span {
  height: 20px;
  width: 20px;
  display: inline-block;
  border: 2px solid #0033a1;
  margin-right: 10px;
}

.big-se-pro-item {
  display: flex;
  align-items: center;
}

.big-product-flex {
  background: #fff;
  padding: 15px 0 15px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.big-product-conten h5 a {
  font-size: 22px;
  font-weight: 600;
  margin: 5px 0;
  display: block;
}

.big-product-conten h6 {
  color: #00af43;
  font-size: 14px;
  font-weight: 600;
}

.big-product-cat h6 {
  color: #1a1a1a;
}

.big-product-cat .po-bedi-cont {
  text-align: left;
}

.big-product-cat .po-bedicon img {
  width: 38px !important;
}

.big-page-flex {
  display: flex;
  justify-content: space-between;
}

.big-select-product-items {
  width: 66%;
  background: #fff;
  padding: 20px;
}

.big-summary-area {
  background: #fff;
  height: 100%;
  padding: 20px;
  width: 32%;
}

.big-product-cat .po-bed-items {
  border-bottom: none;
}

.big-product-cat .po-bed-item {
  margin: 30px 18px 24px 0;
}

.big-product-cat .po-bedicon {
  margin-right: 8px;
}

.big-summary-item p {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}

p.big-summary-total {
  font-weight: 600;
}

.big-summary-area button.bg-00a860 {
  width: 100%;
  border: none;
  padding: 10px;
  color: #fff;
  font-size: 14px;
  margin-top: 30px;
  transition: all 0.3s ease;
}

.big-summary-area button.bg-00a860:hover {
  background: #00a1e0;
  color: #fff;
}

.big-summary-area button.bg-00a860 i {
  font-size: 12px;
  margin-left: 23px;
}

.big-rem-pro-item span {
  cursor: pointer;
}

img.big-conpany-logo {
  width: 56px;
}

.big-select-product-item {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.big-se-pro-item {
  position: relative;
  cursor: pointer;
}

.big-se-pro-item.big-se-pro-item-active::after {
  position: absolute;
  top: 1px;
  left: 0px;
  content: "\2713";
  font-weight: 700;
  background: #0033a1;
  padding: 0px 4px;
  line-height: 1.3;
  color: #fff;
}

.page-heading-top {
  margin-left: 12px;
  margin-bottom: 30px;
}

.summary-active .big-summary-area {
  position: sticky;
  right: 0;
  top: 80px;
}

.count-input {
  position: relative;
  width: 100%;
  max-width: 120px;
}

.count-input input {
  width: 100%;
  height: 36.92307692px;
  border: none;
  border-radius: 2px;
  background: none;
  text-align: center;
  color: #00a1e0;
  font-weight: 700;
  font-size: 18px;
}

.count-input input:focus {
  outline: none;
}

.count-input .incr-btn {
  display: block;
  position: absolute;
  width: 26px;
  height: 26px;
  font-size: 22px;
  font-weight: 300;
  text-align: center;
  line-height: 24px;
  top: 50%;
  right: 0;
  margin-top: -11px;
  text-decoration: none;
}

.count-input .incr-btn:first-child {
  right: auto;
  left: 0;
  top: 46%;
  background: #7c868d;
  border-radius: 50%;
  color: #fff;
}

.count-input .incr-btn:last-child {
  left: auto;
  right: 0;
  top: 46%;
  background: #7c868d;
  border-radius: 50%;
  color: #fff;
}

.count-input.count-input-sm {
  max-width: 125px;
}

.count-input.count-input-sm input {
  height: 36px;
}

.count-input.count-input-lg {
  max-width: 200px;
}

.count-input.count-input-lg input {
  height: 70px;
  border-radius: 3px;
}

.bg-select-prod-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 15px;
}

.all-select-delete {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.all-select-delete p {
  color: #ff4949;
  font-size: 12px;
  margin-left: 10px;
}

.all-select span {
  height: 24px;
  width: 24px;
  display: inline-block;
  position: relative;
  font-size: 19px;
  background: #0033a1;
}

.all-select span::after {
  position: absolute;
  top: 0;
  left: 6px;
  content: "\002B";
  font-weight: 700;
  line-height: 1.3;
  color: #fff;
}

.all-select {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.all-select.all-selectitem-active span::after {
  content: "\2212";
}

.all-select p {
  margin-left: 10px;
  font-size: 12px;
}

.bg-order-its {
  display: flex;
  align-items: center;
  width: 66%;
  justify-content: space-between;
}

.userDashboardOrders .big-select-product-items {
  width: 80%;
}

.bg-order-it p {
  color: rgba(165, 165, 165, 1);
  font-weight: 300;
  font-size: 14px;
}

.bg-order-it.orderId45 h6 {
  text-align: right;
  color: #00a1e0;
}

.btn-big a {
  padding: 10px 20px;
  display: inline-block;
  margin-top: 18px;
  border: 1px solid #00a1e0;
  color: #00a1e0;
  margin-right: 10px;
}

.btn-big a:hover {
  color: #fff;
  background: #00a1e0;
}

.btn-big a i {
  margin-right: 6px;
}

.btn-big a.bg-003d94 {
  padding: 10px 20px;
  display: inline-block;
  margin-top: 18px;
  border: 1px solid #003d94;
  color: #fff;
  background: #003d94;
}

.big-page-favorites-bg {
  background: #fff;
  padding: 40px 0;
}

.big-page-favorites-bg .bg-select-prod-item {
  display: flex;
  align-items: center;
}

.bg-select-prod-item-heart {
  display: flex;
  align-items: center;
}

.bg-select-prod-item-heart .bg-select-prod-item {
  display: flex;
  align-items: center;
  margin-left: 30px;
}

.bg-select-prod-item-heart .bg-select-prod-item p,
.bg-select-prod-item-heart .bg-select-prod-item i {
  color: #0033a1;
  font-weight: 600;
}

.bg-select-prod-item-heart .bg-select-prod-item .all-select-delete p {
  color: #003;
  font-weight: 600;
}

.flex-fav {
  display: flex;
  align-items: center;
}

.flex-fav i {
  font-size: 20px;
}

.flex-fav p {
  margin-left: 8px;
  font-size: 12px;
}

.big-page-favorites-bg .menu-src-bar {
  margin-right: 0;
}

.big-page-favorites-bg .all-select span {
  margin-right: 15px;
}

.big-page-favorites-bg .menu-src-bar input {
  border: solid 2px solid #e5e7e8;
  padding: 8px 10px;
}

.big-page-favorites-bg button.src-icon {
  color: #003d94;
}

.big-page-favorites-bg .po-bedi-cont {
  text-align: left;
}

.po-home-item-flex {
  display: flex;
  align-items: center;
}

.po-home-item-flex .big-se-pro-item {
  margin: 0 15px;
}

.page-heading-top-flex {
  display: flex;
  justify-content: space-between;
}

.add-new-Message button.bg-003d94 i {
  font-size: 12px;
  margin-right: 10px;
}

.add-new-Message button.bg-003d94 {
  margin-top: 15px;
  background: #003d94;
  border: none;
  padding: 10px 20px;
  font-size: 14px;
  color: #fff;
  transition: all 0.3s ease;
}

.add-new-Message button.bg-003d94:hover {
  background: #0aa0e4;
}

.message-box-sortby {
  display: flex;
  align-items: center;
  margin: 15px 0 15px 15px;
}
.message-box-sortby button.short-by-btn {
  border: transparent;
  background: transparent;
  margin-left: 5px;
  font-size: 10px;
}

.message-box-sortby button.short-by-btn i {
  margin-left: 2px;
}

.message-box-sortby p {
  font-size: 10px;
}

.message-box-flex {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.message-box-left {
  width: 32%;
  border-right: 1px solid rgba(124, 134, 141, 0.2);
  position: relative;
}
.message-box-right {
  width: 68%;
  padding: 15px;
  position: relative;
}
.message-box-list-item-flex {
  display: flex;
  align-items: center;
}

.message-box-list-item h6 img {
  width: 20px;
  border-radius: 50%;
  margin-right: 5px;
}

.message-box-list-item h6 {
  display: flex;
  align-items: flex-end;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.4;
  position: relative;
  margin-bottom: 5px;
}

.message-box-list-item h6 span {
  position: absolute;
  right: 0;
  font-size: 10px;
  color: rgba(124, 134, 141, 0.8);
  font-weight: 400;
}

.message-box-list-item p {
  font-size: 10px;
}

.message-box-list-item {
  margin-left: 10px;
}

.message-box-list-item-flex {
  padding: 15px;
}

.message-box-list-item-flex.message-box-list-item-active {
  background: #dceeff;
}

.message-pagination-area {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
}

.message-pagination-area ul {
  display: flex;
  align-items: center;
}

.message-pagination-area ul li {
  padding: 5px 0px;
}

.message-pagination-area ul li a {
  height: 30px;
  width: 26px;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  font-size: 10px;
}
.message-pagination-area ul li a i {
  font-size: 14px;
}
.message-pagination-area ul li a.active,
.message-pagination-area ul li a:hover {
  background: #0aa0e4;
  color: #fff;
}

.message-box-top h6 img {
  width: 30px;
  border-radius: 50%;
  margin-right: 8px;
}

.message-box-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
.message-box-top h5 {
  font-size: 10px;
  display: flex;
  align-items: center;
}

.message-box-top h6 {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
}

.message-box-top h5 i {
  font-size: 18px;
  margin-right: 8px;
}

.chat-left-item {
  width: 50%;
  position: relative;
  margin: 10px 0;
}

.chat-left-content {
  background: rgba(124, 134, 141, 0.2);
  padding: 10px;
  border-radius: 10px;
  position: relative;
}

.chat-left-content p {
  font-size: 14px;
}

.chat-left-date p {
  font-size: 10px;
}

.chat-left-date {
  text-align: right;
  margin-bottom: 5px;
}

.chat-left-content img {
  position: absolute;
  right: -40px;
  top: 0;
  width: 30px;
  border-radius: 50%;
}

.chart-right-item {
  width: 50%;
  position: relative;
  float: right;
  margin: 10px 0;
}

.chat-right-content {
  background: #dceeff;
  padding: 10px;
  border-radius: 10px;
}

.chat-right-content p {
  font-size: 14px;
}

.chat-right-date p {
  font-size: 10px;
}

.chat-right-date {
  text-align: right;
  margin-top: 5px;
}

.chat-right-content img {
  position: absolute;
  left: -40px;
  top: 0;
  width: 30px;
  border-radius: 50%;
}
.chat-left-item-area {
  width: 100%;
}
.chart-right-item-area {
  width: 100%;
  display: inline-block;
}

.chat-text-area {
  display: flex;
  align-items: center;
  margin-top: 150px;
}
.input-chat {
  position: relative;
}

.chat-emoji {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

.input-chat input {
  width: 100%;
  padding: 8px;
  background: #f4f4f4;
  border: transparent;
}

.input-chat {
  width: 100%;
}

button.chat-text-file,
button.chat-text-sent {
  background: transparent;
  border: none;
  margin-right: 15px;
}
button.chat-text-sent {
  background: transparent;
  border: none;
  margin-left: 15px;
}
.chat-emoji i {
  color: rgba(124, 134, 141, 0.4);
  font-size: 20px;
}

.confirmation-dialog-area button.bg-00a860 {
  border: none;
  padding: 8px 26px;
  color: #fff;
}
.confirmation-dialog-area button.bg-00a860:hover {
  background: #ff4949;
}
.confirmation-dialog-area button.bg-ff4949 {
  border: none;
  padding: 8px 26px;
  color: #fff;
  background: #ff4949;
}
.confirmation-dialog-area button.bg-ff4949:hover {
  background: #00a860;
}
.confirmation-dialog-area .modal-body h4 {
  display: flex;
  align-items: center;
  font-size: 28px;
  font-weight: 600;
}
.confirmation-dialog-area .modal-body h4 img {
  margin-right: 10px;
}
.confirmation-dialog-area .modal-body p {
  font-size: 18px;
  margin-left: 44px;
  margin-top: 5px;
}
.confirmation-dialog-area .modal-dialog {
  max-width: 400px;
}
.confirmation-dialog-area .modal-footer {
  padding: 0 20px 10px;
  border: none;
}
.wishlist-positio {
  padding: 15px;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.15);
  background: #fff;
  width: 400px;
  display: inline-block;
  position: fixed;
  top: 100px;
  right: 40px;
}
.wishlist-positio .bg-00a860 {
  padding: 10px 20px;
  color: #fff;
  display: inline-block;
  float: right;
  margin-top: 30px;
}

.wishlist-positio h4 {
  display: flex;
  align-items: center;
}
.wishlist-positio h4 img {
  margin-right: 10px;
}
.wishlist-close {
  position: absolute;
  right: 16px;
  cursor: pointer;
}

.tab-flex-items-tabitems {
  display: flex;
  margin-top: 16px;
  padding: 0 10px;
}
.tab-flex-items-tabitems .tab-items-img img {
  width: 36px;
}

.not-sub-menu {
  position: absolute;
  left: 0;
  width: 300px;
  background: #fff;
  opacity: 0;
  visibility: hidden;
  padding-top: 22px;
  box-shadow: 0 69px 300px 0 rgba(0, 0, 0, 0.28);
  z-index: -1;
}
.noti-menu-item {
  position: relative;
  padding: 17px 0;
}

.not-sub-menu .nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.not-sub-menu .nav-tabs .nav-item.show .nav-link,
.not-sub-menu .nav-tabs .nav-link.active {
  color: #495057;
  background-color: transparent;
  border-color: none;
}

.not-sub-menu .nav-tabs .nav-link:focus,
.not-sub-menu .nav-tabs .nav-link:hover {
  border-color: transparent;
}
.not-sub-menu .nav-tabs .nav-item.show .nav-link,
.not-sub-menu .nav-tabs .nav-link.active {
  border-color: transparent;
  border-bottom: 2px solid #398fee;
  color: #398fee;
  width: 100%;
}
.not-sub-menu .tab-items-cont h6 {
  font-size: 14px;
  font-weight: 600;
}
.not-sub-menu .tab-items-cont p {
  font-size: 10px;
}

.not-sub-menu .tab-items-cont {
  margin-left: 10px;
}
.not-sub-menu .tab-items-cont {
  margin-left: 10px;
  width: 100%;
}
.not-sub-menu ul#myTab li.nav-item a {
  margin: 0;
}
.not-sub-menu .nav-tabs .nav-item {
  margin-bottom: -1px;
  width: 50%;
}
.not-sub-menu .tab-items-cont strong {
  font-size: 10px;
  float: right;
}
.not-sub-menu .tab-flex-items-tabitemarea {
  min-height: 200px;
  padding-bottom: 100px;
}
.not-sub-menu .all-mark-read {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%;
  transform: translate(-50%);
  text-align: center;
}
.not-sub-menu .all-mark-read a {
  color: #00a1e0;
  font-weight: 600;
  font-size: 14px;
  border-top: 1px solid #979797;
  display: block;
  padding: 16px 0;
}

.noti-menu-item:hover .not-sub-menu {
  opacity: 1;
  visibility: visible;
}

.big-page-favorites-bg .po-home-item {
  margin: 21px 15px 10px 0;
}
/*~~~~~~~~~~~~~~~~~~  5section page End ~~~~~~~~~~~~~~~~~~ */
