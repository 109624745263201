@media only screen and (max-width: 1365px) {
}

@media only screen and (min-width: 995px) {
  .container-area {
    margin-top: -15px !important;
  }
}

@media only screen and (max-width: 1199px) {
  .filter-src-area {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .banner-content h2 {
    font-size: 40px;
  }

  .banner-content h5 {
    font-size: 18px;
    color: #fff;
  }

  .menu-src-bar {
    margin-right: 0;
  }

  .colors-area .owl-carousel .owl-nav button.owl-prev img,
  .colors-area .owl-carousel .owl-nav button.owl-next img {
    width: 50px !important;
  }

  .colors-area .owl-nav {
    height: 50px;
  }

  .back-menu h2 {
    font-size: 32px;
  }

  .colors-area button.owl-next {
    right: 5%;
  }

  .colors-area button.owl-prev {
    left: 5%;
  }

  .gallery-2-area .tab-more-photos {
    margin-right: 5px;
  }

  .price-range-item.range-1 {
    width: 170px;
  }

  .price-range-item.range-1 input,
  .price-range-item.range-2 input {
    width: 90px;
  }

  .price-range-cont p {
    font-size: 12px;
  }

  .price-range-item input {
    font-size: 11px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .post-item .po-home-item-headitop h4,
  .post-flex h5 {
    font-size: 10px;
  }

  .error-box {
    right: 5px;
  }

  .po-start-price.col-00af43 {
    border-right: 0;
  }
}

@media (min-width: 991px) and (max-width: 1199px) {
  .po-car-items .po-bedi-cont h6 {
    font-size: 13px;
  }

  .po-car-items .po-bedi-cont p {
    font-size: 12px;
  }

  .po-car-items .po-car-item-left a h4 {
    font-size: 16px;
  }

  .po-start-price h6 {
    font-size: 12px;
  }

  .po-start-price p {
    font-size: 12px;
  }

  .similar-cars-area button.owl-next {
    right: 0%;
  }

  .similar-cars-area button.owl-prev {
    left: 0%;
  }

  .next-move-cont {
    padding: 30px 20px 40px;
  }
  .landing-page-slider-content {
    bottom: 100px;
  }
  .landing-page-slider-area .owl-dots {
    bottom: 0;
  }
}

/**************** Blog-Page Area End ****************/
@media only screen and (max-width: 991px) {
  .transform-active {
    position: relative;
  }

  .transform-active::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: #fff;
    z-index: 99;
    transition: all 0.3s ease;
  }

  .top-header p {
    font-size: 10px;
    margin-right: 20px;
    margin: 0 10px 0 0;
  }

  .po-home-item {
    flex-wrap: wrap;
  }

  .menu-src-bar input {
    width: 320px;
    font-size: 12px;
  }

  .po-home-item-right,
  .po-home-item-left {
    width: 100%;
  }

  .card-sin-jo a {
    font-size: 16px;
  }

  .card-sin-jo a {
    margin: 0 4px;
    font-size: 14px;
    padding: 3px 3px;
  }

  a.add-to-card {
    order: 1;
  }

  .mo-none {
    display: none !important;
  }

  ul#navigation .pc-none,
  .pc-none {
    display: block;
  }

  .logo {
    padding: 10px 0px;
  }

  .menu-src-bar {
    position: absolute;
    top: 100%;
    width: auto;
    left: 50%;
    transform: translate(-50%);
    display: none;
  }

  .card-sin-jo a i {
    font-size: 16px;
  }

  .menu-src-bar input {
    width: 320px;
    padding: 10px;
    background: #fff;
  }

  .card-sin-jo span {
    top: -10px;
    right: -8px;
    height: 16px;
    width: 16px;
    font-size: 8px;
  }

  .po-home-item-right {
    order: -1;
  }

  .po-home-img img {
    width: 100%;
  }

  ul#navigation li a {
    display: block;
    padding: 6px 11px;
    font-weight: 400;

    font-size: 15px;
  }

  .mobile-sign-item.pc-none {
    position: absolute;
    top: 50px;
    left: 0;
    z-index: 999;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    padding-top: 100px;
  }

  .mobile-sign-item.pc-none a {
    display: block;
    text-align: center;
    font-size: 20px;
    padding: 10px;
    margin-bottom: 20px;
  }

  .transform-active .mobile-sign-item.pc-none {
    opacity: 1;
    visibility: visible;
    background: #fff;
    height: 100vh;
  }

  .input-box input,
  .attachment-file-item label {
    font-size: 14px;
  }

  .location-item a {
    font-size: 11px;
  }

  .attachment-file-items,
  .price-range-items {
    flex-wrap: wrap;
  }

  .attachment-file-item {
    margin-top: 20px;
  }

  .agency-information-items .input-box {
    width: 48%;
  }

  h6.error-all {
    margin-top: 0;
  }
  .next-move-items {
    flex-wrap: wrap;
  }

  .next-move-item {
    width: 48%;
    margin-top: 80px;
  }

  .photo-pop-box-area {
    flex-wrap: wrap;
  }

  .photo-pop-box-slder,
  .photo-view-form {
    width: 100%;
  }

  .photo-view-slider-area .modal-header .close {
    position: absolute;
    right: 15px;
  }

  .photo-view-form {
    margin-top: 0;
  }

  .card-menu-items {
    position: inherit;
  }

  .cart-menu-dropdown {
    left: 0;
    width: 100%;
  }

  .add-to-number {
    position: absolute;
    right: 15px;
  }

  a.add-to-item {
    position: relative;
  }

  .photo-view-slider-area .owl-carousel .owl-item .slider-thumb img {
    height: auto;
  }

  ul.join-sub {
    display: none;
  }

  .owl-carousel .owl-item .fullscreen-view-img img {
    height: auto;
  }
}
@media only screen and (max-width: 767px) {
  .fl-src,
  .fl-filter,
  .fl-room,
  .fl-bathrooms,
  .fl-others,
  .fl-form,
  .fl-price,
  .fl-labels {
    width: 100%;
    margin: 10px 0;
  }

  .home-top-menu ul li a {
    padding: 12px 42px;
  }

  .po-home-item-headitop,
  .po-bed-items,
  .po-bed-items,
  .po-bed-item,
  .fl-clear-short {
    flex-wrap: wrap;
  }

  .po-bed-item {
    align-items: center;
    margin: 10px 0;
    text-align: center;
    justify-content: center;
  }

  .po-bedicon {
    width: 100%;
    margin-right: 0;
  }

  .po-home-item-headitop h4 {
    font-size: 18px;
    margin-bottom: 0;
  }

  .po-start-price {
    width: 100%;
    margin-bottom: 10px;
  }

  .po-start-price.col-00af43 {
    border-right: none;
  }

  .pagination ul li a {
    padding: 4px 10px;
  }

  .fl-price {
    margin-top: 20px;
  }

  #navigation {
    top: 105px;
  }

  .sticky #navigation {
    top: 83px;
  }

  .mobile-menu {
    top: 60px;
    margin-left: 10px;
  }

  .fl-clears {
    margin-bottom: 10px;
    width: 100%;
  }

  .agencies-item .po-bedi-cont {
    text-align: center;
  }

  .post-item .po-home-item-headitop h4,
  .post-flex h5 {
    font-size: 12px;
  }

  .post-item .po-home-item-heading p {
    margin-top: 0px;
  }

  .slider-item {
    height: 460px;
  }

  .posted-houses-area {
    position: relative;
    margin-top: 50px;
  }

  .slider-area .owl-dots {
    right: 0;
    top: 90%;
    width: 100%;
  }

  .banner-content h2 {
    font-size: 32px;
  }

  .banner-content h5 {
    font-size: 16px;
  }

  .banner-btn a {
    padding: 10px 40px;
  }

  .posted-rearch input {
    background: #ffff;
    padding: 0 54px;
    font-size: 12px;
  }

  button.srcicon {
    box-shadow: 0 0px 26px 0 rgba(0, 0, 0, 0.2);
  }

  h2.fost-main-heading {
    color: #1a1a1a;
  }

  .location-src {
    flex-wrap: wrap;
    width: 100%;
  }

  .location-src .fl-form {
    width: 100%;
  }

  .home-top-menu.care-memu ul li a {
    padding: 10px 30px;
  }

  .fl-room.fl-bath-wi,
  .fl-room.fl-rome-wi {
    margin-right: 20px;
  }

  .fl-room {
    margin-bottom: 20px;
  }

  .fl-room.fl-fuel-wi {
    margin-right: 20px;
  }

  .po-start-card {
    width: 100%;
  }

  .po-start-card .bg-0033a1 {
    width: 100%;
  }

  .po-home-item-headitop > a {
    margin-bottom: 6px;
  }

  .po-home-items .po-start-prices {
    flex-wrap: wrap;
  }

  .po-home-items .po-start-price.col-00af43 {
    width: 52%;
  }

  .po-home-items .po-start-price {
    width: 48%;
  }

  .po-home-items .po-start-prices {
    margin: 15px 0 0;
  }

  h2.sign-heading {
    font-size: 30px;
  }

  .sing-up-flex,
  .attachment-file-items,
  .back-register {
    flex-wrap: wrap;
  }

  .sing-up-left,
  .sing-up-right,
  .agency-information-items .input-box,
  .attachment-file-item {
    width: 100%;
  }

  .sing-up-flex::after {
    display: none;
  }

  .sing-up-right {
    margin-top: 40px;
  }

  .attachment-file-item {
    margin-top: 20px;
  }

  .input-box input,
  .attachment-file-item label {
    font-size: 16px;
  }

  .location-item a {
    font-size: 16px;
  }

  .pd-area {
    padding: 0 15px;
  }

  .back-register button.theme-btn.bg-none {
    margin-bottom: 10px;
  }

  .error-box {
    width: 220px;
    right: 5px;
  }

  .error-content h4 {
    font-size: 22px;
  }

  .error-content p {
    margin-top: 6px;
    font-size: 13px;
  }

  .post-item-img {
    height: 334px;
  }

  .sing-up-flex {
    padding: 0 15px;
  }

  h3.avilable-color-heading {
    font-size: 22px;
  }

  .available-color-flex {
    margin-top: 10px;
  }

  .available-color-flex ul li a {
    height: 20px;
    width: 20px;
    margin-left: 5px;
  }

  .available-color-flex ul li.bg-fff.active a {
    width: 30px;
    height: 30px;
  }

  .gallery-heading h2 {
    font-size: 38px;
  }

  .gallery-area {
    padding-top: 30px;
  }

  .gallery-heading p {
    font-size: 15px;
  }

  .gallery-item a {
    bottom: 10px;
    font-size: 20px;
  }

  .play-video img {
    width: 40px;
  }

  .gallery-area ul#pills-tab {
    position: relative;
    bottom: 0px;
    left: 0px;
  }

  .gallery-2-area .nav-link {
    padding: 0 0 4px 0;
  }

  .gallery-2-area .po-photo-Velar {
    width: 100%;
  }

  .gallery-2-area .po-share {
    width: 100%;
    justify-content: space-between;
    margin-top: 8px;
  }

  .gallery-2-area .po-home-cont-top,
  .gallery-2-area .po-start-prices {
    flex-wrap: wrap;
  }

  .gallery-2-area .po-share img {
    margin-left: 0;
  }

  .gallery-2-area .tab-more-photos {
    width: 100%;
  }

  .gallery-2-area .po-start-price {
    width: 50%;
    margin: 10px 0 20px;
  }

  .gallery-2-area .po-start-card {
    margin: auto;
  }

  .description-tab-area .nav-tabs,
  .specifications-items,
  .engineDetails-item,
  .chart-area {
    flex-wrap: wrap;
  }

  .description-tab-area .nav-tabs .nav-item,
  .chart-content {
    width: 100%;
  }

  .specifications-item {
    width: 50%;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
  }

  .specifications-item-img {
    width: 100%;
    text-align: center;
    margin-bottom: 5px;
  }

  .engineDetails-item-left {
    width: 100%;
    margin: 5px 0;
  }

  .price-range-item.range-1,
  .price-range-item.range-2,
  .price-range-item.range-3,
  .main-chart {
    width: 100%;
  }
  .next-move-item {
    width: 100%;
  }
  .next-move-cont {
    padding: 30px 20px 40px;
  }
  .want-sell-cont-area {
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
  }
  .want-sell-cont {
    width: 100%;
    margin-bottom: 15px;
  }
  .next-move-area h3.allheading-con {
    text-align: center;
  }
  .landing-page-slider-item h2 {
    font-size: 30px;
    line-height: 1.2;
  }
  .landing-page-slider-item p {
    font-size: 16px;
  }
  .owl-carousel .owl-item img {
    height: 300px;
  }
  .landing-page-slider-content {
    bottom: 30px;
  }
  .landing-page-slider-area .owl-dots {
    bottom: -40px;
  }

  .filter-menu-area,
  .filter-menu-warp .filter-footer {
    flex-wrap: wrap;
  }

  .filter-menu-items,
  .filter-menu-warp .fl-items-all {
    width: 100%;
  }

  .filter-menu-details {
    width: 100%;
    padding-left: 15px;
    height: 80vh;
  }

  .filter-menu-warp .fl-src {
    width: 146px;
    margin: 10px 0;
  }

  .filter-menu-warp .modal-dialog {
    margin: 0;
  }

  .filter-menu-items {
    height: 80vh;
  }

  .filter-material-items ul li {
    width: 32%;
  }

  .filter-material-items ul li img {
    width: 100%;
  }

  .filter-menu-details {
    box-shadow: 2px 14px 30px 0 rgba(0, 0, 0, 0.1),
      2px 1px 12px 0 rgba(0, 0, 0, 0.03);
  }

  .filter-size-items .fl-labels {
    width: 40%;
  }

  .filter-size-items .fl-price {
    width: 53%;
  }
}

@media only screen and (max-width: 500px) {
  .po-start-price h6 {
    font-size: 12px;
  }

  .po-start-price p {
    font-size: 12px;
  }

  .po-start-price.col-00af43 p span {
    margin-right: 4px;
  }
}

@media only screen and (max-width: 360px) {
  ul#navigation li a {
    padding: 6px 5px;
    font-size: 14px;
  }

  .post-flex h5 strong {
    margin-left: 6px;
  }

  .post-item .po-home-item-headitop h4,
  .post-flex h5 {
    font-size: 11px;
  }

  .po-car-item-left a h4 {
    font-size: 15px;
  }

  .pagination ul li a {
    font-size: 10px;
  }

  .po-start-price h6 {
    font-size: 10px;
  }

  .po-start-price p {
    font-size: 10px;
  }

  .home-top-menu.care-memu ul li a {
    padding: 8px 24px;
  }
}

@media only screen and (max-width: 1300px) {
  .left-menu-area {
    width: 230px;
    padding: 30px 20px;
  }

  .big-select-product-items {
    width: 70%;
    padding: 15px;
  }

  .big-summary-area {
    padding: 15px;
    width: 28%;
  }
}

@media only screen and (max-width: 1199px) {
  .big-product-cat .po-bed-item {
    margin: 20px 6px 24px 0;
    flex-wrap: wrap;
    justify-content: center;
  }

  .menu-profile {
    padding: 0 8px;
    cursor: pointer;
  }

  .page-wrap {
    padding: 0;
  }

  .left-menu-area {
    width: 200px;
    padding: 30px 15px;
  }

  .big-select-product-items {
    width: 69%;
    margin-left: 10px;
  }

  .big-summary-item p {
    font-size: 12px;
  }

  .profile-menu-dropdown {
    left: -120px;
  }

  .userDashboardOrders .big-select-product-items {
    width: 100%;
  }
}

@media only screen and (max-width: 1023px) {
  .big-page-area,
  .big-select-product-items,
  .big-summary-area {
    width: 100%;
  }

  .big-page-flex {
    flex-wrap: wrap;
  }

  .left-menu-area {
    width: 300px;
    position: fixed;
    margin-left: -300px;
    z-index: 9999999;
    transition: all 0.3s ease;
    height: 100%;
  }

  .profiel-menu-active .left-menu-area {
    margin-left: 0;
  }

  .big-summary-area {
    margin-top: 40px;
  }

  .menu-profile {
    display: none;
  }

  .mobile-menu-icon {
    display: flex !important;
  }

  .page-wrap {
    padding: 0;
    margin-top: 0;
  }

  .page-heading-top {
    margin-top: 40px;
  }

  .big-select-product-items {
    margin-left: 0;
  }

  .left-main-menu-item {
    min-height: auto;
    margin-top: 15px;
  }

  .left-main-menu-item ul li {
    margin: 10px 0;
  }
}

@media only screen and (max-width: 767px) {
  .big-product-flex,
  .userDashboardOrders .big-select-product-item-top-flex {
    flex-wrap: wrap;
  }

  .big-product-img img,
  .big-product-img {
    width: 100%;
  }

  .big-product-conten h5 a {
    font-size: 18px;
  }

  .big-product-conten h6 {
    font-size: 12px;
  }

  .big-rem-pro-item {
    display: flex;
    margin-left: 0;
  }

  .big-rem-pro-item span {
    margin-left: 0;
    margin-right: 15px;
  }

  .bg-order-its {
    width: 100%;
    flex-wrap: wrap;
  }

  .bg-order-it {
    margin: 10px 0;
  }

  .bg-select-prod-item-heart {
    flex-wrap: wrap;
  }

  .po-home-item-flex .po-start-prices,
  .message-box-flex,
  .page-heading-top-flex {
    flex-wrap: wrap;
  }

  .message-box-left,
  .message-box-right {
    width: 100%;
  }

  .chat-left-item,
  .chart-right-item {
    width: 80%;
  }

  .chat-text-area {
    margin-top: 100px;
  }

  .message-pagination-area {
    position: relative;
  }

  .message-box-left {
    border-right: none;
  }
  .noti-menu-item {
    position: inherit;
  }
  .not-sub-menu {
    left: 50%;
    transform: translate(-50%);
  }
  .wishlist-positio {
    width: 300px;
    top: 60px;
    right: 0;
  }
  .wishlist-positio h4 {
    font-size: 18px;
  }
  .add-new-Message {
    margin-left: 15px;
  }
  .add-new-Message button.bg-003d94 {
    margin-top: -20px;
    margin-bottom: 16px;
    margin-left: 0;
  }
  .big-select-product-item-top-flex {
    padding: 24px 10px;
  }
  .big-select-product-item-top {
    padding: 0;
  }
  .count-input {
    max-width: 26px;
  }
  .count-input .incr-btn:last-child {
    top: -8px;
  }
  .count-input .incr-btn:first-child {
    top: 108%;
  }
  .big-select-product-item-top {
    width: 90%;
  }
  .count-input input {
    font-size: 16px;
  }
  .page-heading-top-flex {
    display: inherit;
    justify-content: space-between;
  }
  .bg-select-prod-item .flex-fav p {
    display: none;
  }
  .bg-select-prod-item-heart .bg-select-prod-item .all-select-delete p {
    display: none;
  }
  .big-page-favorites-bg .po-home-item .po-share {
    display: none;
  }
  .big-page-favorites-bg .po-bed-items {
    justify-content: space-around;
  }
  .big-page-favorites-bg .po-start-price {
    width: 42%;
  }
  .bg-order-it.orderId45 {
    order: -1;
    text-align: center;
    width: 100%;
    margin-top: 0;
  }
  .bg-order-it.orderId45 h6 {
    text-align: center;
  }
  .big-page-favorites-bg .po-home-item-flex {
    flex-wrap: wrap;
  }
  .big-page-favorites-bg .po-home-item {
    margin: 18px;
  }
  .po-home-item-flex .big-se-pro-item {
    margin: 20px 15px 0;
  }
  .big-select-product-items .po-bed-items {
    flex-wrap: nowrap;
  }
}
